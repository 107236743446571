import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { EmailOutlined as EmailIcon } from '@material-ui/icons'
import { AutoAwesome as MagicIcon } from '@mui/icons-material'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import * as R from 'ramda'
import type { ChangeEvent, FocusEvent } from 'react'
import { Component, Fragment } from 'react'
import PhoneInput from 'react-phone-input-2'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
// @ts-ignore react-tooltip has no exported types
import ReactTooltip from 'react-tooltip'
// @ts-ignore package with no exported types
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import type { BusinessTypeWithId } from '../../../../src/types/business'
import type { AddressType, Image, RegionBasicType } from '../../../../src/types/common'
import type { Timestamp } from '../../../../src/types/firebase'
import type { JobAdType } from '../../../../src/types/jobAds'
import type { EnumJobType } from '../../../../src/types/jobTypes'
import { loadGoogleMaps } from '../../helpers/googleMaps'
import { getAllJobTypes } from '../../helpers/jobs'
import { standardized } from '../../helpers/phoneNumbers'
import { getCurrencyByBusiness } from '../../helpers/regionSelector'
import { getClosestRegionFromCoordinates } from '../../helpers/regions'
import { errorToast } from '../../helpers/toast'
import { isValidEmail, isValidURL } from '../../helpers/validator'
import ImageHrWoman from '../../img/Woman.svg'
import { StaffersJobsAPI } from '../../staffers/api/firestore/https/jobs'
import { getBusinessById } from '../../staffers/api/getters/business'
import { getJobTypes } from '../../staffers/api/getters/common'
import integrationsAPI from '../../staffers/api/integrations'
import { parseAddressFromDetails } from '../../staffers/api/location'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import moment from '../../util/moment'
import RichTextEditor from '../RichTextEditor/RichTextEditor'
import JobAdPreview from '../jobAdPreview/JobAdPreview'
import { promptAddBusinessWebsiteModal } from '../modals/AddBusinessWebsiteModal'
import { FormItem, FormSection } from '../mui/FormBlocks'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import { withTracking } from '../providers/PerfProvider'
import styles from './CreateFulltimeJob.module.css'
import CreateFulltimeJobForm from './CreateFulltimeJobForm'
import CreateFulltimeJobGallery from './CreateFulltimeJobGallery/CreateFulltimeJobGallery'
import type { ErrorObject, PossibleErrorNames } from './fulltimeErrors'
import {
  ALL_ERRORS,
  COVERAGE_MAX,
  COVERAGE_MIN,
  ErrorHandler,
  INITIAL_ERRORS,
  MIN_SEASONAL_TEXT_LENGTH,
  MIN_TEXT_LENGTH,
  POSITION_MAX,
  POSITION_MIN,
} from './fulltimeErrors'

const POSSIBLE_CURRENCIES = ['NOK', 'SEK'] // , 'EUR'

const MIN_HOURLY_WAGE = 160
const MAX_HOURLY_WAGE = 600
const MIN_MONTHLY_WAGE = 20_000
const MAX_MONTHLY_WAGE = 100_000

const wageLimits = {
  hour: {
    min: MIN_HOURLY_WAGE,
    max: MAX_HOURLY_WAGE,
  },
  month: {
    min: MIN_MONTHLY_WAGE,
    max: MAX_MONTHLY_WAGE,
  },
}

const defaultJobAdDescription = `
Tell potential employees more about this job

<br>

<br>

**Key Duties and Responsibilities:**
 - add key duty or responsibility
 - add another
 - add another

**Requirements:**
 - add requirement
 - add another
 - add another

**Benefits:**
 - add benefit
 - add another
 - add another

**Accommodation:**

Add a short description about the accommodation.
`.trim()

const MOCK = false
const mockGenDescription = async (text: string): Promise<{ text: string }> =>
  new Promise((res) =>
    setTimeout(() => {
      text +=
        '\n\nIt was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
      res({
        text,
      })
    }, 4000)
  )

const defaultDate = moment(new Date()).add(1, 'M').startOf('month').toDate()

const defaultAboutJobOffer = 'Write something about your company'

type CustomEventTarget = {
  name: PossibleErrorNames
  value: any
  type: string
  checked: boolean
}

type PartialJobAdType = Pick<
  JobAdType,
  | 'aboutUs'
  | 'businessName'
  | 'businessId'
  | 'createdBy'
  | 'description'
  | 'contractCoverage'
  | 'isPaidUpfront'
  | 'coverImageUrl'
  | 'coverImageData'
  | 'customJobAdUrl'
  | 'images'
  | 'imagesData'
  | 'jobType'
  | 'positions'
  | 'timeStart'
  | 'isEmailNotifEnabled'
  | 'oneWayQuestions'
  | 'seasonalJobInfo'
  | 'contactEmail'
  | 'contactPhone'
  | 'wage'
>

type Props = {
  business: BusinessTypeWithId
  businessId: string
  managerId: string
  perf: any
  closeModal: (jobAdId: string, isJobAd: boolean) => () => void
  isPaidUpfront?: boolean
  jobTypes: {
    values: Array<EnumJobType>
  }
  jobAd?: JobAdType
  jobAdId?: string
} & RouteComponentProps

type State = {
  areImagesUnsaved: boolean
  noImage: boolean
  description: string
  aboutUs: string
  contractCoverage: number
  coverImageBlob: Blob | null
  coverImage: string
  coverImageData: Image | null
  imageBlobs: (Blob | string)[] | null
  images: string[]
  imagesData: Image[]
  customJobAdUrl: string
  customJobType: string
  jobType: string
  selectedJobType: string
  positions: number
  timeStart: ReturnType<typeof moment> | null
  isOneWayInterview: boolean
  oneWayQuestions: string
  postingInProgress: boolean
  seasonalJobInfo: string
  isEmailNotifEnabled: boolean
  formErrors: ErrorObject[] // keeps track of all errors (even on mount, to check for submit)
  displayedErrors: ErrorObject[] // we dont display all errors on mount, acts as a separate array
  isSeasonalJob: boolean
  step: number
  showPreview: boolean
  showWage: boolean
  wageCurrency: 'NOK' | 'SEK' | 'EUR'
  wageRange: [number | '', number | '']
  wageUnit: 'hour' | 'month'
  phone: string
  email: string
  gmapsLoaded: boolean
  needsLocationUpdate: boolean
  useCustomAddress: boolean
  beautifulAddress?: string
  location: { lat: number; lng: number } | null
  locationDescription: string
  locationPlaceId: string
  region: RegionBasicType
  address: AddressType | null
  isGeneratingBusinessDescription: boolean
  isGeneratingJobAdDescription: boolean
  useGeneratedBusinessDescription: boolean
  useGeneratedJobAdDescription: boolean
}

class CreateFulltimeJob extends Component<Props, State> {
  cache:
    | {
        [place_id: string]: {
          region: RegionBasicType
          formatted_address: string
          geometry: {
            location: { lat: number; lng: number } | null
          }
        }
      }
    | Record<string, never>

  constructor(props: Props) {
    super(props)
    this.cache = {}
    // If we are editing jobs, load relevant data
    if (props.jobAd) {
      const {
        aboutUs,
        contactEmail,
        contactPhone,
        contractCoverage,
        coverImageUrl,
        coverImageData,
        customJobAdUrl,
        description,
        isEmailNotifEnabled,
        jobType,
        images,
        imagesData,
        oneWayQuestions,
        positions,
        timeStart,
        whatWeOffer,
        seasonalJobInfo,
        address,
        wage,
        placeId,
      } = props.jobAd
      const { jobTypes, business } = props
      const jobTypeOptions = getAllJobTypes(jobTypes ? jobTypes.values : [])
      const selectedJobType = jobTypeOptions.find((option) => option === jobType)
      const isCustomJobType = !selectedJobType
      const region = props.jobAd.region || props.business?.region || ({ countryName: 'Norway' } as RegionBasicType)
      const businessCoverImage = business && business.coverImageUrl

      let aboutUsText = aboutUs ?? business?.description
      if (whatWeOffer) {
        aboutUsText = [aboutUsText, whatWeOffer].filter(Boolean).join('\n')
      }

      this.state = {
        areImagesUnsaved: false,
        noImage: false,
        aboutUs: aboutUsText, // backwards compatibility
        // converts the % into a number
        contractCoverage: parseInt(contractCoverage.slice(0, -1), 10),
        coverImage: coverImageUrl || (business && business.coverImageUrl) || '',
        coverImageData: coverImageData
          ? coverImageData
          : businessCoverImage
            ? {
                imageUrl: businessCoverImage,
                imageName: 'Business cover',
                imageSize: 0,
                imageFormat: '',
                createdAt: '',
              }
            : null,
        coverImageBlob: null,
        imageBlobs: images || null,
        images: images || [],
        imagesData: imagesData || [],
        customJobType: isCustomJobType ? jobType : '',
        customJobAdUrl: customJobAdUrl || '',
        description,
        displayedErrors: [],
        formErrors: INITIAL_ERRORS,
        isEmailNotifEnabled,
        isOneWayInterview: !!oneWayQuestions,
        jobType,
        oneWayQuestions,
        positions,
        postingInProgress: false,
        timeStart: !timeStart ? null : moment(timeStart),
        selectedJobType: isCustomJobType ? 'Other' : selectedJobType,
        isSeasonalJob: !!seasonalJobInfo,
        seasonalJobInfo,
        step: 1,
        phone: contactPhone || '',
        email: contactEmail || '',
        showWage: !!wage,
        showPreview: false,
        wageCurrency: wage?.currency || getCurrencyByBusiness(business),
        wageRange: wage ? [wage.min, wage.max] : [MIN_HOURLY_WAGE + 40, MAX_HOURLY_WAGE - 100],
        wageUnit: wage?.unit || 'hour',
        gmapsLoaded: false,
        needsLocationUpdate: false,
        useCustomAddress: false,
        address,
        beautifulAddress: undefined,
        location: null,
        locationDescription: '',
        locationPlaceId: placeId || '',
        region,
        isGeneratingJobAdDescription: false,
        isGeneratingBusinessDescription: false,
        useGeneratedBusinessDescription: false,
        useGeneratedJobAdDescription: false,
      }
    } else {
      const businessCoverImage = props.business && props.business.coverImageUrl
      const region = props.business?.region || ({ countryName: 'Norway' } as RegionBasicType)
      this.state = {
        areImagesUnsaved: false,
        noImage: false,
        aboutUs: props.business.description || '',
        contractCoverage: COVERAGE_MAX,
        coverImage: businessCoverImage || '',
        coverImageData: businessCoverImage
          ? {
              imageUrl: businessCoverImage,
              imageName: 'Business cover',
              imageSize: 0,
              imageFormat: '',
              createdAt: '',
            }
          : null,
        coverImageBlob: null,
        imageBlobs: null,
        customJobType: '',
        customJobAdUrl: '',
        description: defaultJobAdDescription,
        displayedErrors: [],
        images: [],
        imagesData: [],
        formErrors: INITIAL_ERRORS,
        isEmailNotifEnabled: true,
        isOneWayInterview: false,
        jobType: '',
        oneWayQuestions: `
        - Tell us about your previous experiences
        - What motivates you at work?
        - What is your best skill that complements this job?
        `,
        positions: 1,
        postingInProgress: false,
        timeStart: moment(defaultDate),
        selectedJobType: '',
        step: 1,
        isSeasonalJob: false,
        seasonalJobInfo: '',
        showWage: true,
        wageUnit: 'month',
        showPreview: false,
        wageCurrency: getCurrencyByBusiness(props.business),
        wageRange: [MIN_MONTHLY_WAGE + 4000, MAX_MONTHLY_WAGE - 40000],
        phone: props.business?.phone || '',
        email: '',
        gmapsLoaded: false,
        needsLocationUpdate: false,
        useCustomAddress: false,
        beautifulAddress: undefined,
        address: props.business.address,
        location: null,
        locationDescription: '',
        locationPlaceId: props.business.placeId || '',
        region,
        isGeneratingBusinessDescription: false,
        isGeneratingJobAdDescription: false,
        useGeneratedBusinessDescription: false,
        useGeneratedJobAdDescription: false,
      }
    }
  }

  componentDidMount() {
    const { business } = this.props
    const { needsLocationUpdate, locationPlaceId, phone } = this.state
    if (!phone.startsWith('+')) this.setPhone(phone)
    loadGoogleMaps(() => {
      this.setState(
        {
          gmapsLoaded: true,
        },
        async () => {
          if (needsLocationUpdate || (business && business.placeId !== locationPlaceId)) {
            await this.onLocationChange({ place_id: business?.placeId || locationPlaceId }, true)
          }
        }
      )
    })
    ReactTooltip.rebuild()
  }

  componentDidUpdate() {
    const { coverImageData, imagesData, noImage } = this.state
    const isImage = !coverImageData && !imagesData.length

    if (isImage && !noImage) {
      this.onNoImageChange(true)
    } else if (!isImage && noImage) {
      this.onNoImageChange(false)
    }
  }

  generateBusinessDescription = async () => {
    // aboutUs
    // TODO, once we split the create job into multiple steps, the generator should already be in progress
    // and the state change would only cause loader to appear (see staffers-web-app-next repo)
    try {
      this.setState({ isGeneratingBusinessDescription: true })
      const response = MOCK
        ? await mockGenDescription('This is business description')
        : await integrationsAPI.post('/integrations/generate/business-description')
      const { status, text, title, detail } = response
      if (status === 200) {
        if (text) {
          this.setState({ aboutUs: text, useGeneratedBusinessDescription: true })
        }
      } else {
        toast.error(detail)
        console.error(`Error generating business description:\n${title}\n${detail}`)
      }
    } catch (e) {
      console.error(`There was a problem when generating a description for business.\n${e}`)
    } finally {
      this.setState({ isGeneratingBusinessDescription: false })
    }
    setTimeout(async () => {})
  }

  generateJobAdDescription = async () => {
    const { business } = this.props

    // description
    const { jobType, customJobType, positions, timeStart, contractCoverage } = this.state
    const _jobType = jobType === 'Other' ? customJobType : jobType
    if (!_jobType) {
      return toast.warn('please select a job position first')
    }

    if (!business?.website) {
      const isSuccessful = await promptAddBusinessWebsiteModal(business.id)

      if (!isSuccessful) {
        return toast.warn('You need to add a business website to generate content from')
      }
    }

    // TODO, once we split the create job into multiple steps, the generator should already be in progress
    // and the state change would only cause loader to appear (see staffers-web-app-next repo)
    const generateBoth = !this.state.aboutUs
    try {
      this.setState({ isGeneratingJobAdDescription: true })
      if (generateBoth) {
        this.setState({ isGeneratingBusinessDescription: true })
      }
      const response = MOCK
        ? await mockGenDescription('This is job ad description')
        : await integrationsAPI.post('/integrations/generate/job-ad-description', {
            jobType: _jobType,
            numberOfPositions: positions,
            startDate: moment(timeStart ?? new Date())
              .toISOString(true)
              .split('T')[0],
            contract: `${contractCoverage}%`,
          })
      const { status, text, businessDescription, title, detail } = response
      if (status === 200) {
        if (text) {
          this.setState({ description: text, useGeneratedJobAdDescription: true })
        }
        if (generateBoth && businessDescription) {
          this.setState({ aboutUs: businessDescription, useGeneratedBusinessDescription: true })
        }
      } else {
        toast.error(detail)
        console.error(`Error generating job ad description:\n${title}\n${detail}`)
      }
    } catch (e) {
      console.error(`There was a problem when generating a description for job ad.\n${e}`)
    } finally {
      this.setState({ isGeneratingJobAdDescription: false })
      if (generateBoth) {
        this.setState({ isGeneratingBusinessDescription: false })
      }
    }
  }

  getJobAdData = (): PartialJobAdType => {
    const { business, isPaidUpfront, managerId } = this.props
    const {
      aboutUs,
      description,
      contractCoverage,
      images,
      imagesData,
      coverImage,
      coverImageData,
      customJobAdUrl,
      customJobType,
      jobType,
      positions,
      timeStart,
      isEmailNotifEnabled,
      isOneWayInterview,
      oneWayQuestions,
      isSeasonalJob,
      seasonalJobInfo,
      email: contactEmail,
      phone: contactPhone,
      showWage,
      wageRange,
      wageUnit,
      wageCurrency,
    } = this.state

    const useCustomJobType = jobType === 'Other'
    const jobTypeToPreview = useCustomJobType && customJobType ? customJobType : jobType

    const jobData: PartialJobAdType = {
      aboutUs,
      businessName: business && business.businessName,
      businessId: business && business.businessId,
      createdBy: managerId,
      description,
      contractCoverage: `${contractCoverage}%`,
      customJobAdUrl,
      isPaidUpfront: !!isPaidUpfront,
      coverImageUrl: coverImage,
      coverImageData,
      images,
      imagesData,
      jobType: jobTypeToPreview,
      positions,
      timeStart: timeStart && (timeStart.toDate() as unknown as Timestamp),
      isEmailNotifEnabled,
      oneWayQuestions: isOneWayInterview ? oneWayQuestions : '',
      seasonalJobInfo: isSeasonalJob ? seasonalJobInfo : '',
      contactEmail,
      contactPhone,
      wage:
        showWage && wageRange
          ? {
              min: +wageRange[0],
              max: +wageRange[1],
              unit: wageUnit,
              currency: wageCurrency,
            }
          : null,
    }

    return jobData
  }

  renderStepOne = () => {
    const { jobTypes, closeModal, jobAdId, business } = this.props
    const {
      aboutUs,
      contractCoverage,
      customJobAdUrl,
      customJobType,
      description,
      jobType,
      selectedJobType,
      positions,
      timeStart,
      isEmailNotifEnabled,
      displayedErrors,
      isSeasonalJob,
      seasonalJobInfo,
      showWage,
      wageCurrency,
      wageUnit,
      wageRange,
      phone,
      email,
      beautifulAddress,
      gmapsLoaded,
      useCustomAddress,
      useGeneratedBusinessDescription,
      useGeneratedJobAdDescription,
      isGeneratingBusinessDescription,
      isGeneratingJobAdDescription,
    } = this.state
    const { website } = business

    const jobTypeOptions = ['Other', ...getAllJobTypes(jobTypes ? jobTypes.values : [])]

    return (
      <Fragment>
        {/* @ts-ignore invokation close, not sure why */}
        <ModalHeader close={closeModal}>{jobAdId ? 'Edit Job Ad' : 'Post a Job Ad'}</ModalHeader>
        <Box className={muiModalStyles.modalContent}>
          <CreateFulltimeJobForm
            jobTypeOptions={jobTypeOptions}
            // @ts-ignore
            handleLocationChange={this.onLocationChange}
            handleCustomLocationToggle={this.onUseCustomAddressToggle}
            // @ts-ignore
            handleLocationBlur={this.onLocationBlur}
            // @ts-ignore legacy change function types
            handleDateChange={this.handleDateChange}
            // @ts-ignore legacy change function types
            handleOptionChange={this.handleOptionChange}
            // @ts-ignore legacy change function types
            handleInputChange={this.handleInputChange}
            values={{
              contractCoverage,
              customJobType,
              jobType,
              selectedJobType,
              positions,
              timeStart: timeStart as Date | null,
              isSeasonalJob,
              seasonalJobInfo,
            }}
            formErrors={displayedErrors}
            // @ts-ignore legacy change function types
            handleBlur={this.handleBlur}
            beautifulAddress={beautifulAddress}
            gmapsLoaded={gmapsLoaded}
            useCustomAddress={useCustomAddress}
          />
          <FormSection spacing={2} title="Wage options">
            <FormItem label="" xs={12}>
              <FormControlLabel
                label="Show wage"
                control={<Checkbox checked={showWage} name="showWage" onChange={this.toggleShowWage} color="primary" />}
              />
            </FormItem>
            {showWage && (
              <Fragment>
                <FormItem label="Wage range" xs={6}>
                  <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                    <Grid item xs={3}>
                      <TextField
                        id="wage-range-1"
                        name="wageRange"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          // @ts-ignore
                          min: wageLimits[wageUnit].min,
                          max: wageLimits[wageUnit].max,
                        }}
                        value={wageRange[0]}
                        onChange={this.setMinWageRange}
                        // @ts-ignore legacy change function types
                        onBlur={this.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Slider
                        color="secondary"
                        {...wageLimits[wageUnit]}
                        value={wageRange.map(Number)}
                        step={{ hour: 10, month: 100 }[wageUnit]}
                        // @ts-ignore weird slider-type-map typing
                        onChange={this.setWageRange}
                        valueLabelDisplay="off"
                        aria-labelledby="range-slider"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="wage-range-2"
                        name="wageRange"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          // @ts-ignore
                          min: wageLimits[wageUnit].min,
                          max: wageLimits[wageUnit].max,
                        }}
                        value={wageRange[1]}
                        // @ts-ignore legacy change function types
                        onChange={this.setMaxWageRange}
                        // @ts-ignore legacy change function types
                        onBlur={this.handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <ErrorHandler formErrors={displayedErrors} errorName="wageRange" />
                </FormItem>
                <FormItem label="Currency per time" xs={2}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      displayEmpty
                      fullWidth
                      name="wageCurrency"
                      value={wageCurrency}
                      onChange={this.setWageCurrency}
                      required
                      renderValue={(value) => (
                        <div className={value ? '' : styles.placeholderColor}>{(value as string) || 'Select...'}</div>
                      )}
                    >
                      {POSSIBLE_CURRENCIES.map((currency: string) => (
                        <MenuItem value={currency} key={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormItem>
                <FormItem label="" xs={4}>
                  <RadioGroup
                    row
                    aria-label="wage unit"
                    color="default"
                    value={wageUnit}
                    // @ts-ignore weird MUI typing
                    onChange={this.setWageUnit}
                  >
                    <FormControlLabel value="hour" control={<Radio name="hourly-wage" />} label="Hour" />
                    <FormControlLabel value="month" control={<Radio name="monthly-wage" />} label="Month" />
                  </RadioGroup>
                </FormItem>
              </Fragment>
            )}
          </FormSection>
          <FormSection title="Company contacts">
            <FormItem label="Phone number">
              <PhoneInput
                autoFormat
                // @ts-ignore weird MUI typing
                name="phone"
                country={business.region?.countryName === 'Sweden' ? 'se' : 'no'}
                value={phone}
                error={displayedErrors.some(({ name }) => name === 'phone')}
                onChange={this.setPhone}
                onBlur={this.handleBlur}
                buttonStyle={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                inputStyle={{ width: '100%', padding: '26px 0 26px 64px', borderRadius: '10px' }}
              />
              <ErrorHandler formErrors={displayedErrors} errorName="phone" />
            </FormItem>
            <FormItem label="Email">
              <TextField
                name="email"
                variant="outlined"
                fullWidth
                type="text"
                error={displayedErrors.some(({ name }) => name === 'email')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter your email"
                value={email}
                // @ts-ignore legacy change function types
                onBlur={this.handleBlur}
                onChange={this.handleInputChange}
              />
              <ErrorHandler formErrors={displayedErrors} errorName="email" />
            </FormItem>
          </FormSection>
          <FormSection title="Position and company description">
            <FormItem label="About this job" highlighted xs={12}>
              <Box mb={1}>
                {!useGeneratedJobAdDescription && (
                  <Box
                    style={{
                      backgroundColor: '#DFF7E4',
                      borderRadius: '8px',
                      backgroundImage: `url('${ImageHrWoman}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '95% 20px',
                    }}
                    p={3}
                    pr={15}
                    mb={1}
                  >
                    <Typography variant="h5" style={{ paddingBottom: '8px' }}>
                      Are you busy? Use HR Assistant!
                    </Typography>
                    <Typography variant="body1" style={{ color: '#737373' }}>
                      {website
                        ? 'Our HR assistant will automatically generate a unique job description for you.'
                        : 'Fill the website field in your company profile and the HR assistant will automatically generate a unique job description for you.'}
                    </Typography>
                  </Box>
                )}
                <Button
                  fullWidth
                  style={{ borderRadius: '8px' }} // TODO remove after full redesign
                  onClick={this.generateJobAdDescription}
                  color="primary"
                  variant="contained"
                  startIcon={<MagicIcon />}
                  disabled={!!isGeneratingJobAdDescription}
                >
                  {!useGeneratedJobAdDescription
                    ? 'Click here to have the HR-assistant write the job description for you'
                    : 'Re-generate your job description'}
                </Button>
              </Box>
              <RichTextEditor
                // @ts-ignore legacy change function types
                onBlur={(e) => this.handleBlur({ ...e, target: { ...e.target, name: 'description' } })}
                onChange={(value) => {
                  this.handleInputChange({
                    // @ts-ignore weird MUI typing
                    target: {
                      value,
                      name: 'description',
                    },
                  })
                }}
                placeholder="Tell potential employees more about this job"
                initValue={description || 'Tell potential employees more about this job'}
                isLoading={isGeneratingJobAdDescription}
              />
              <ErrorHandler formErrors={displayedErrors} errorName="description" />
            </FormItem>
            <FormItem label="About us and what we offer" highlighted xs={12}>
              <Box mb={1}>
                <Button
                  fullWidth
                  style={{ borderRadius: '8px' }} // TODO remove after full redesign
                  onClick={this.generateBusinessDescription}
                  color="primary"
                  variant="contained"
                  disabled={!!isGeneratingBusinessDescription || !website}
                  startIcon={<MagicIcon />}
                >
                  {!useGeneratedBusinessDescription
                    ? 'Click here for the HR assistant to write the description for you'
                    : 'Re-generate your business details'}
                </Button>
              </Box>
              <RichTextEditor
                // @ts-ignore legacy change function types
                onBlur={(e) => this.handleBlur({ ...e, target: { ...e.target, name: 'aboutUs' } })}
                onChange={(value) => {
                  this.handleInputChange({
                    // @ts-ignore weird MUI typing
                    target: {
                      value,
                      name: 'aboutUs',
                    },
                  })
                }}
                placeholder={defaultAboutJobOffer}
                initValue={aboutUs || defaultAboutJobOffer}
                isLoading={isGeneratingBusinessDescription}
              />
              <ErrorHandler formErrors={displayedErrors} errorName="aboutUs" />
            </FormItem>
            <FormItem label="Do you want candidates to apply through an external link?" xs={12}>
              <TextField
                name="customJobAdUrl"
                variant="outlined"
                fullWidth
                type="text"
                error={displayedErrors.some(({ name }) => name === 'customJobAdUrl')}
                placeholder="Add link to your job ad"
                value={customJobAdUrl}
                // @ts-ignore legacy change function types
                onBlur={this.handleBlur}
                onChange={this.handleInputChange}
              />
              <ErrorHandler formErrors={displayedErrors} errorName="customJobAdUrl" />
            </FormItem>
          </FormSection>
          <FormSection title="Notification">
            <FormItem label="">
              <FormControlLabel
                label="Would you like email notifications for this job?"
                control={
                  <Switch
                    name="notification-switch"
                    checked={isEmailNotifEnabled}
                    onChange={this.onToggleNotifications}
                    color="primary"
                  />
                }
              />
            </FormItem>
          </FormSection>
        </Box>
      </Fragment>
    )
  }

  renderStepTwo = () => {
    const { closeModal, jobAd } = this.props
    const { coverImageData, imagesData, imageBlobs, displayedErrors } = this.state

    return (
      <Fragment>
        {/* @ts-ignore close modal with invokation */}
        <ModalHeader close={closeModal}>Post a Job Ad - Images</ModalHeader>
        <div style={{ overflowY: 'auto' }}>
          <CreateFulltimeJobGallery
            onCoverImageChange={this.onCoverImageChange}
            onImagesChange={this.onImagesChange}
            onBlobsChange={this.onBlobsChange}
            coverImageData={coverImageData}
            galleryImages={imagesData}
            galleryBlobs={imageBlobs}
            coverImageUrl={jobAd?.coverImageUrl}
          />
        </div>
        <Box p={1}>
          <ErrorHandler formErrors={displayedErrors} errorName="unsaved-images" />
          <ErrorHandler formErrors={displayedErrors} errorName="no-image" />
        </Box>
      </Fragment>
    )
  }

  togglePreview = () => {
    this.setState(({ showPreview }) => ({
      showPreview: !showPreview,
    }))
  }

  render() {
    const { jobAdId, business } = this.props
    const { postingInProgress, step, displayedErrors, areImagesUnsaved, showPreview, imageBlobs, coverImageBlob } =
      this.state

    if (postingInProgress) {
      return (
        <Box p={3} textAlign="center">
          <BeatLoader color="gray" />
        </Box>
      )
    }
    return (
      <Fragment>
        {showPreview ? (
          <JobAdPreview
            business={business}
            imageBlobs={imageBlobs}
            coverImageBlob={coverImageBlob}
            jobAd={this.getJobAdData()}
            togglePreview={this.togglePreview}
          />
        ) : (
          <Fragment>
            {step === 1 && this.renderStepOne()}
            {step === 2 && this.renderStepTwo()}
          </Fragment>
        )}
        <Box p={3} textAlign="right">
          {step === 1 && (
            <Button
              // @ts-ignore legacy change function types
              onClick={() => this.handleSubmit(this.goToNextStep)}
              disabled={displayedErrors.length !== 0}
              color="primary"
              variant="contained"
              id="next-btn"
            >
              Next
            </Button>
          )}
          {step >= 2 && (
            <Grid container spacing={3} justifyContent="space-between">
              {areImagesUnsaved && (
                <Grid item xs={12}>
                  <Typography style={{ textAlign: 'center', color: 'red' }}>
                    Please save or cancel the image upload before proceeding
                  </Typography>
                </Grid>
              )}
              <Grid item xs={3}>
                <Button
                  fullWidth
                  onClick={showPreview ? this.togglePreview : this.goToPreviousStep}
                  disabled={displayedErrors.length !== 0 || areImagesUnsaved}
                  variant="contained"
                >
                  Go back
                </Button>
              </Grid>
              {!showPreview && (
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={this.togglePreview}
                    disabled={displayedErrors.length !== 0 || areImagesUnsaved}
                    variant="contained"
                  >
                    Preview
                  </Button>
                </Grid>
              )}
              <Grid item xs={3}>
                <Button
                  id="post-job-button"
                  fullWidth
                  onClick={() => this.handleSubmit(this.postJobAd)}
                  disabled={displayedErrors.length !== 0 || areImagesUnsaved}
                  color="primary"
                  variant="contained"
                >
                  {jobAdId ? 'Edit job' : 'Post job'}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Fragment>
    )
  }

  goToNextStep = () =>
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }))

  goToPreviousStep = () =>
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }))

  handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    const { formErrors, displayedErrors } = this.state

    const newDisplayError = formErrors.find((err) => err.name === name)
    if (newDisplayError) {
      this.setState((state) => ({
        displayedErrors: [...new Set([...state.displayedErrors, newDisplayError])],
      }))
    }
    // clean-up from onChange formErrors
    displayedErrors.forEach((error) => {
      const feNames = formErrors.map((fe) => fe.name)
      // this is a really weird call, but id rather not touch / break it
      // eslint-disable-next-line no-unused-expressions
      feNames.includes(error.name) ||
        this.setState((state) => ({
          displayedErrors: state.displayedErrors.filter((de) => de.name !== error.name),
        }))
    })
  }

  onUnsavedImagesChange = (isUnsaved: boolean) => {
    this.setState(
      {
        areImagesUnsaved: isUnsaved,
      },
      () => {
        this.handleErrors('unsaved-images')
      }
    )
  }

  onNoImageChange = (isImage: boolean) => {
    this.setState(
      {
        noImage: isImage,
      },
      () => {
        this.handleErrors('no-image')
      }
    )
  }

  onCoverImageChange = async (coverImageBlob: Blob | null, image?: Image) => {
    if (coverImageBlob) {
      this.setState({
        coverImageBlob,
        coverImage: image?.imageUrl || URL.createObjectURL(coverImageBlob),
        coverImageData: image || null,
      })
    } else {
      this.setState({
        coverImageBlob: null,
        coverImage: '',
        coverImageData: null,
      })
    }
  }

  onBlobsChange = (imageBlobs: (Blob | string)[]) => {
    this.setState({
      imageBlobs,
    })
  }

  onImagesChange = (imgArray: Image[]) => {
    const images = imgArray.map((img) => img.imageUrl)
    this.setState({
      images,
      imagesData: imgArray || [],
    })
  }

  // onImagesChange = (i: number) => async (blob: Blob) => {
  //   const { images, imageBlobs } = this.state
  //   let updatedImages: Array<string | null> = [...images]
  //   updatedImages[i] = blob ? URL.createObjectURL(blob) : null // create url so that image will be displayed after crop even if it was not uploaded yet
  //   updatedImages = updatedImages.filter(Boolean)
  //   const updatedBlobs = [...(imageBlobs || [])]
  //   updatedBlobs[i] = blob || undefined
  //   this.setState({
  //     images: updatedImages as string[],
  //     imageBlobs: updatedBlobs,
  //   })
  // }

  onDeleteImage = (index: number) => {
    const { images } = this.state
    if (index < images.length) {
      this.setState((prevState) => ({
        images: R.remove(index, 1)(prevState.images) as string[],
        imageBlobs: R.remove(index, 1)(prevState.imageBlobs || []) as Blob[],
      }))
    }
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore weird typing
    const { target }: { target: CustomEventTarget } = event
    const { value, checked, type, name } = target

    if (name === 'positions' || name === 'contractCoverage') {
      const minConstraint = name === 'contractCoverage' ? COVERAGE_MIN : POSITION_MIN
      const maxConstraint = name === 'contractCoverage' ? COVERAGE_MAX : POSITION_MAX
      const valueAsNumber = Math.round(+value)
      // Only allow update if the number is within (pretty arbitrary) constraints or if number is
      // undefined (user deleted input)
      if (!value || (valueAsNumber >= 1 && valueAsNumber <= 1000)) {
        this.setState(
          // @ts-ignore dynamic state mapping
          {
            [name]: value === '' ? '' : valueAsNumber,
          },
          () => this.handleErrors(name)
        )
      } else {
        errorToast(`Please enter value between ${minConstraint || 1} and ${maxConstraint || 100}.`)
      }
    } else if (type === 'radio' || type === 'checkbox') {
      this.setState(
        // @ts-ignore dynamic state mapping
        {
          [name]: checked,
        },
        () => this.handleErrors(name)
      )
    } else {
      this.setState(
        // @ts-ignore dynamic state mapping
        {
          [name]: value,
        },
        () => this.handleErrors(name)
      )
    }
  }

  handleOptionChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState(
      {
        selectedJobType: event.target.value,
        jobType: event.target.value,
      },
      () => this.handleErrors('jobType')
    )

  handleDateChange = (timeStart: ReturnType<typeof moment> | null) => {
    this.setState(
      {
        timeStart,
      },
      () => this.handleErrors('timeStart')
    )
  }

  onUseCustomAddressToggle = () => {
    const { business, jobAd } = this.props

    // @ts-ignore
    this.setState(
      ({ useCustomAddress, region, locationPlaceId }) => ({
        // if we toggle custom address off
        // use business region (since it will be the job region), otherwise use custom region
        region: useCustomAddress ? (business.region as RegionBasicType) : region,
        locationPlaceId: useCustomAddress ? jobAd?.placeId || locationPlaceId : business?.placeId || locationPlaceId,
        useCustomAddress: !useCustomAddress,
      }),
      async () => {
        // We need to recalculate google places autocomplete input value
        // and region if we toggled custom adddress back on
        await this.onLocationChange({ place_id: this.state.locationPlaceId })
      }
    )
  }

  onLocationChange = async (place: { place_id: string }, initial = false) => {
    try {
      // Since the component is written in shit way with multiple re-renders
      // we implement a basic caching mechanism to avoid multiple requests
      // and hence rather expensive places API billing
      if (this.state.gmapsLoaded) {
        const [details] = await geocodeByPlaceId(place.place_id)

        const address = parseAddressFromDetails(details)

        const getLoc = <T extends number | (() => number)>({
          lat,
          lng,
        }: {
          lat: T
          lng: T
        }): { lat: number; lng: number } =>
          typeof lat === 'function' && typeof lng === 'function'
            ? ({ lat: lat(), lng: lng() } as { lat: number; lng: number })
            : ({ lat, lng } as { lat: number; lng: number })

        const { lat, lng } = getLoc(details.geometry.location)

        const region = await getClosestRegionFromCoordinates(lat, lng)
        // We cache the result to prevent excessive fetching
        this.cache = {
          ...this.cache,
          [place.place_id]: {
            ...details,
            region,
          },
        }
        this.setState({
          beautifulAddress: details ? details.formatted_address : '',
          address,
          locationDescription: details ? details.formatted_address : '',
          locationPlaceId: place.place_id,
          location: details ? getLoc(details.geometry.location as { lat: number; lng: number }) : null,
          region,
        })
      }
    } catch (error) {
      // We don't allow for posting jobs outside of available regions
      console.error(error)
      if (!initial) {
        errorToast((error as Error)?.message ?? 'Failed to change location')
      }
    }
  }

  onLocationBlur = (event: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event
    const placeId = this.state.locationPlaceId
    if (value) {
      this.onLocationChange({ place_id: placeId })
    } else {
      this.setState({
        beautifulAddress: '',
        locationDescription: '',
        locationPlaceId: '',
        location: null,
      })
    }
  }

  onToggleInterview = () => {
    this.setState(
      (state) => ({
        isOneWayInterview: !state.isOneWayInterview,
      }),
      () => this.handleErrors('oneWayQuestions')
    )
  }

  onToggleNotifications = () =>
    this.setState((state) => ({
      isEmailNotifEnabled: !state.isEmailNotifEnabled,
    }))

  toggleShowWage = () => {
    this.setState(
      ({ showWage }) => ({
        showWage: !showWage,
      }),
      () => this.handleErrors('wageRange')
    )
  }

  setWageRange = (_: ChangeEvent<HTMLInputElement>, val: [number, number]) => {
    this.setState(
      {
        wageRange: val,
      },
      () => this.handleErrors('wageRange')
    )
  }

  setMinWageRange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const min = value === '' ? value : +value
    const { wageRange } = this.state
    this.setState(
      {
        wageRange: [min, wageRange[1]],
      },
      () => this.handleErrors('wageRange')
    )
  }

  setMaxWageRange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const max = value === '' ? value : +value
    const { wageRange } = this.state
    this.setState(
      {
        wageRange: [wageRange[0], max],
      },
      () => this.handleErrors('wageRange')
    )
  }

  setWageCurrency = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    this.setState({
      wageCurrency: event.target.value as 'NOK' | 'SEK' | 'EUR',
    })
  }

  setWageUnit = (_: ChangeEvent<HTMLInputElement>, val: 'hour' | 'month') => {
    const { wageUnit, wageRange } = this.state
    if (val === wageUnit) {
      return
    }
    if (val !== 'hour' && val !== 'month') {
      console.error(val)
    }
    // convert range values based on the new units
    const cap = (n: number) => Math.floor(Math.max(wageLimits[val].min, Math.min(n, wageLimits[val].max)))
    const newRange = wageRange.map((n) => cap(((n as number) / wageLimits[wageUnit].max) * wageLimits[val].max))

    this.setState(
      {
        wageUnit: val,
        // @ts-ignore wage range specifc
        wageRange: newRange,
      },
      () => this.handleErrors('wageRange')
    )
  }

  setPhone = (val: string) => {
    this.setState(
      {
        phone: `+${val}`,
      },
      () => this.handleErrors('phone')
    )
  }

  handleErrors = (name: PossibleErrorNames) => {
    // Functions for operations with formError state
    const addError = (errorName: PossibleErrorNames) => {
      const newError = ALL_ERRORS.find((error) => error.name === errorName)
      const { formErrors } = this.state
      if (newError && !formErrors.includes(newError)) {
        this.setState((state) => ({
          formErrors: [...state.formErrors, newError],
        }))
      }
    }

    const removeError = (errorName: PossibleErrorNames) =>
      this.setState((state) => ({
        formErrors: state.formErrors.filter((error) => error.name !== errorName),
        displayedErrors: state.displayedErrors.filter((error) => error.name !== errorName),
      }))

    switch (name) {
      // 0.
      case 'unsaved-images': {
        const { areImagesUnsaved } = this.state
        if (areImagesUnsaved) {
          addError('unsaved-images')
        } else {
          removeError('unsaved-images')
        }
        break
      }

      // 1. Special case: Positions min-max
      case 'positions': {
        const { positions } = this.state
        if (positions < POSITION_MIN || positions > POSITION_MAX) {
          addError('positions')
        } else {
          removeError('positions')
        }
        break
      }
      // 2. Special case: if toggler enabled
      case 'oneWayQuestions': {
        // we need to check if notifications are enabled, if they are, check for min length
        const { isOneWayInterview } = this.state
        if (isOneWayInterview) {
          const { oneWayQuestions } = this.state
          // eslint-disable-next-line no-unused-expressions
          oneWayQuestions.length < MIN_TEXT_LENGTH ? addError('oneWayQuestions') : removeError('oneWayQuestions')
        } else {
          removeError('oneWayQuestions')
        }
        break
      }

      // 3. Special case: Contract coverage min-max
      case 'contractCoverage': {
        const { contractCoverage } = this.state
        if (contractCoverage < COVERAGE_MIN || contractCoverage > COVERAGE_MAX) {
          addError('contractCoverage')
        } else {
          removeError('contractCoverage')
        }
        break
      }

      // 4. Special case: may be null but not undefined
      case 'timeStart': {
        const { timeStart } = this.state
        if (timeStart === undefined) {
          // can be null
          addError(name)
        } else {
          removeError(name)
        }
        break
      }

      // 5. null/empty like
      case 'jobType': {
        const { [name]: value, formErrors, selectedJobType } = this.state
        // remove customJobType error if the selectedJobType changes from "Other"
        if (selectedJobType !== 'Other' && !!formErrors.find((error) => error.name === 'customJobType')) {
          removeError('customJobType')
        }
        // eslint-disable-next-line no-unused-expressions
        !value ? addError(name) : removeError(name)
        break
      }

      // 6. If isSeasonalJob === true, there needs to be a description
      case 'isSeasonalJob':
      case 'seasonalJobInfo': {
        const { seasonalJobInfo, isSeasonalJob } = this.state
        if (isSeasonalJob) {
          // eslint-disable-next-line no-unused-expressions
          seasonalJobInfo.length < MIN_SEASONAL_TEXT_LENGTH ? addError(name) : removeError(name)
        } else {
          removeError('seasonalJobInfo')
        }
        break
      }

      // 7. customJobType case
      case 'customJobType': {
        const { [name]: value, selectedJobType } = this.state
        if (selectedJobType === 'Other') {
          // eslint-disable-next-line no-unused-expressions
          !value ? addError(name) : removeError(name)
        } else {
          removeError(name)
        }
        break
      }

      // 8. email validation
      case 'email': {
        const { [name]: value } = this.state
        if (value && !isValidEmail(value)) {
          addError(name)
        } else {
          removeError(name)
        }
        break
      }

      // 9. validate phone
      case 'phone': {
        const { [name]: value } = this.state
        const isPrefix = value.trim().length <= 3
        if (!isPrefix && value && standardized(value).length < 9) {
          addError(name)
        } else {
          removeError(name)
        }
        break
      }

      // 10. validate phone
      case 'wageRange': {
        const { showWage, wageRange, wageUnit } = this.state
        const isInteger = Number.isInteger(wageRange[0]) && Number.isInteger(wageRange[1])
        const isInRange =
          wageLimits[wageUnit].min <= +wageRange[0] &&
          +wageRange[0] <= +wageRange[1] &&
          +wageRange[1] <= wageLimits[wageUnit].max
        if (showWage && !(isInteger && isInRange)) {
          addError(name)
        } else {
          removeError(name)
        }
        break
      }

      // 11. Validate customJobAdUrl
      case 'customJobAdUrl': {
        const { [name]: value } = this.state
        if (value && !isValidURL(value)) {
          addError(name)
        } else {
          removeError(name)
        }
        break
      }

      case 'no-image': {
        const { noImage, step } = this.state
        if (noImage && step === 2) {
          addError(name)
        } else {
          removeError(name)
        }
        break
      }

      // n. Minimum length fields
      default: {
        const { [name]: value } = this.state
        // eslint-disable-next-line no-unused-expressions
        value.length < MIN_TEXT_LENGTH ? addError(name) : removeError(name)
      }
    }
  }

  resetErrors = () => {
    const allErrors = [
      'jobType',
      'contractCoverage',
      'positions',
      'timeStart',
      'description',
      'aboutUs',
      'oneWayQuestions',
      'seasonalJobInfo',
      'unsaved-images',
      'no-image',
      'email',
      'phone',
      'wageRange',
    ]
    allErrors.forEach((error) => this.handleErrors(error as PossibleErrorNames))
  }

  handleSubmit = (onSuccess: () => Promise<void> | (() => void)) => {
    this.resetErrors()
    this.setState(
      (state) => ({
        displayedErrors: [...new Set([...state.displayedErrors, ...state.formErrors])],
      }),
      async () => {
        const { formErrors } = this.state
        // eslint-disable-next-line no-unused-expressions
        formErrors.length === 0 && (await onSuccess())
      }
    )
  }

  // Will upload / delete images to/from storage and return the new urls
  updateImages = async (jobAdId: string) => {
    const { business } = this.props
    const { coverImageBlob, imageBlobs, images, coverImage, imagesData } = this.state

    // update cover image
    let newCoverImageUrl: string | undefined
    if (coverImageBlob) {
      const storage = getStorage()
      const storageRef = ref(storage, `jobAds/${jobAdId}/images/cover.jpg`)
      await uploadBytes(storageRef, coverImageBlob)
      newCoverImageUrl = await getDownloadURL(storageRef)
    }

    // number in the name of the file might not correspond to its order
    // so we have to find out what names are available to be used / replaced
    const unAvailableIndexes: number[] = []
    images.forEach((url) => {
      const match = url.match(/([0-9]+)\.jpg/)
      if (match) {
        const number = +match[1]
        unAvailableIndexes.push(number)
      }
    })

    // update rest of images
    let updatedImages: string[] = images

    // add added
    if (imageBlobs && imageBlobs.length) {
      let i = 0
      const storage = getStorage()
      const urls = await Promise.all(
        imageBlobs.map(async (blob, index) => {
          if (!(blob instanceof Blob)) {
            return images[index] || ''
          }
          while (unAvailableIndexes.includes(i)) {
            // eslint-disable-next-line no-plusplus
            i++
          }
          const imageName = i
          unAvailableIndexes.push(i)
          const storageRef = ref(storage, `jobAds/${jobAdId}/images/${imageName}.jpg`)
          await uploadBytes(storageRef, blob)
          return getDownloadURL(storageRef)
        })
      )
      const newImagesData = imagesData.map((img, index) => {
        img.imageUrl = urls[index] || img.imageUrl
        return img
      })
      this.setState({
        imagesData: newImagesData,
      })
      updatedImages = urls.filter(Boolean)
    }

    return [newCoverImageUrl || coverImage || business.coverImageUrl, updatedImages]
  }

  postJobAd = async () => {
    const { business, closeModal, history, isPaidUpfront, jobAd, jobAdId, perf } = this.props
    if (business) {
      try {
        this.setState({
          postingInProgress: true,
        })
        const { businessId } = business
        // Find out whether we are editing or creating jobd
        if (jobAdId && jobAd) {
          const {
            aboutUs,
            description,
            contractCoverage,
            customJobType,
            customJobAdUrl,
            coverImageData,
            imagesData,
            jobType,
            positions,
            timeStart,
            isEmailNotifEnabled,
            isOneWayInterview,
            oneWayQuestions,
            seasonalJobInfo,
            email,
            phone,
            showWage,
            wageCurrency,
            wageRange,
            wageUnit,
            useCustomAddress,
            locationPlaceId,
            locationDescription,
            location,
            address,
          } = this.state
          const useCustomJobType = jobType === 'Other'
          const jobTypeToSave = useCustomJobType && customJobType ? customJobType : jobType
          // Make sure we are only editing data that were changed (otherwise we couldn't edit
          // ad that already had applicants, even if we tried to changed description etc)
          const timeStartDate = timeStart && timeStart.toDate()
          const [coverImageUrl, images] = await this.updateImages(jobAdId)

          const jobData: Partial<JobAdType> = {
            businessId,
            aboutUs: aboutUs !== jobAd.aboutUs ? aboutUs : undefined,
            contactPhone: standardized(phone).length >= 9 ? standardized(phone) : '',
            contactEmail: email,
            placeId: locationPlaceId,
            location: useCustomAddress ? locationDescription : '',
            ...(useCustomAddress && location ? { coordinates: location } : {}),
            address,
            description: description !== jobAd.description ? description : undefined,
            contractCoverage: `${contractCoverage}%` !== jobAd.contractCoverage ? `${contractCoverage}%` : undefined,
            coverImageUrl: (coverImageUrl as string) || business.coverImageUrl || '',
            customJobAdUrl,
            images: images as string[],
            imagesData: imagesData || [],
            coverImageData,
            jobType: jobTypeToSave !== jobAd.jobType ? jobTypeToSave : undefined,
            positions: positions !== jobAd.positions ? positions : undefined,
            timeStartDate:
              // 1. case: Job was ASAP, now is specific date
              (jobAd.timeStart === null && !!timeStartDate) ||
              // 2.case: Job was one date, now is another date
              (jobAd.timeStart && !moment(jobAd.timeStart).isSame(timeStartDate)) ||
              // 3. case: Job was one date, now is ASAP
              (timeStart === null && !!jobAd.timeStart)
                ? timeStartDate || null
                : undefined,
            isEmailNotifEnabled: isEmailNotifEnabled !== jobAd.isEmailNotifEnabled ? isEmailNotifEnabled : undefined,
            // eslint-disable-next-line no-nested-ternary
            oneWayQuestions: isOneWayInterview
              ? oneWayQuestions !== jobAd.oneWayQuestions
                ? oneWayQuestions
                : undefined
              : oneWayQuestions !== jobAd.oneWayQuestions
                ? ''
                : undefined,
            seasonalJobInfo: seasonalJobInfo !== jobAd.seasonalJobInfo ? seasonalJobInfo : undefined,
            // @ts-ignore wage specfic
            wage:
              showWage && wageRange
                ? {
                    min: wageRange[0],
                    max: wageRange[1],
                    currency: wageCurrency,
                    unit: wageUnit,
                  }
                : null,
          }
          // Filter out the undefineds from object
          const jobDataFiltered = R.filter((property) => property !== undefined)(jobData)
          await StaffersJobsAPI.jobAdEdit(jobAdId, jobDataFiltered as JobAdType)
          closeModal(jobAdId, true) // this is callback so we need to double call it...
          toast.success('Job ad edited successfully!')
        } else {
          const {
            aboutUs,
            description,
            contractCoverage,
            coverImageBlob,
            coverImageData,
            imageBlobs,
            imagesData,
            customJobType,
            customJobAdUrl,
            jobType,
            positions,
            timeStart,
            isEmailNotifEnabled,
            isOneWayInterview,
            oneWayQuestions,
            isSeasonalJob,
            seasonalJobInfo,
            email,
            phone,
            showWage,
            wageCurrency,
            wageRange,
            wageUnit,
            location,
            locationDescription,
            locationPlaceId,
            useCustomAddress,
            address,
          } = this.state

          const useCustomJobType = jobType === 'Other'
          const jobTypeToSave = useCustomJobType && customJobType ? customJobType : jobType

          const jobData: JobAdType & { timeStartDate: Date | null } = {
            businessId,
            aboutUs,
            placeId: locationPlaceId,
            location: useCustomAddress ? locationDescription : '',
            ...(useCustomAddress && location ? { coordinates: location } : {}),
            address,
            contactPhone: standardized(phone).length >= 6 ? standardized(phone) : '',
            coverImageUrl: business.coverImageUrl || '',
            coverImageData,
            contactEmail: email,
            customJobAdUrl,
            description,
            contractCoverage: `${contractCoverage}%`,
            isPaidUpfront: !!isPaidUpfront,
            isTestJob: !!business.isTestUser,
            jobType: jobTypeToSave,
            positions,
            timeStartDate: timeStart && timeStart.toDate(),
            isEmailNotifEnabled,
            imagesData: imagesData || [],
            oneWayQuestions: isOneWayInterview ? oneWayQuestions : '',
            seasonalJobInfo: isSeasonalJob ? seasonalJobInfo : '',
            // @ts-ignore wage specific
            wage: showWage &&
              wageRange && {
                min: wageRange[0],
                max: wageRange[1],
                currency: wageCurrency,
                unit: wageUnit,
              },
          }
          const trace = perf.trace('Post Job Ad (function time)')
          trace.start()
          const response = await StaffersJobsAPI.jobAdCreate(jobData)
          const newJobId = response.data.jobAdId
          // this only updates if either new cover image url or images are present
          // the default one is pre-uploaded in the jobData above
          if (newJobId && (coverImageBlob || imageBlobs)) {
            // upload images if there are some
            const [coverImageUrl, images] = (await this.updateImages(newJobId)) as [string, string[]]
            await StaffersJobsAPI.jobAdEdit(newJobId, { businessId, images, coverImageUrl, imagesData, coverImageData })
          }
          trace.stop()
          closeModal(newJobId, true) // this is callback so we need to double call it...
          toast.success('Job ad posted successfully!')
          history.push(`/job-ads/${newJobId}`)
        }
      } catch (error) {
        console.warn('error', error)
        errorToast((error as Error)?.message ?? 'Unable to post job, internal error')
      } finally {
        this.setState({
          postingInProgress: false,
        })
      }
    }
  }
}

export default withRouter(
  withTracking(
    connectFirestore(
      (db, props: Props, uid: string) => ({
        jobTypes: getJobTypes(),
        business: getBusinessById(props.businessId),
        managerId: uid,
      }),
      (props: Props) => {
        if (!props.business || !props.jobTypes) {
          return (
            <Box p={3} textAlign="center">
              <BeatLoader color="gray" />
            </Box>
          )
        }
        return <CreateFulltimeJob {...props} />
      }
    )
  )
)
