import { Box, Button, Grid, Typography } from '@material-ui/core'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { ChangeEvent } from 'react'
import { Component } from 'react'
import { BeatLoader } from 'react-spinners'
// @ts-ignore react-select has no exported @types/ module
import Select from 'react-select'
import { toast } from 'react-toastify'
import type { BusinessType } from '../../../../src/types/business'
import type { JobType, JobTypeWithDistance } from '../../../../src/types/jobs'
import { errorToast } from '../../helpers/toast'
import { updateStafferRating } from '../../staffers/api/firestore/staffer.legacy'
import muiButtonStyles from '../mui/Buttons.module.css'
import ModalHeader from '../mui/ModalHeader'
import TextField from '../textInputField/TextInputField'
import styles from './modals.module.css'

type RatingOption = {
  value?: number | null
  label: string | number
}

type State = {
  updatedRating: RatingOption
  updatedComment?: string
  isProcessing: boolean
  canSaveRating: boolean
}

type Props = {
  currentRating?: number | null
  currentComment?: string
  initialRating?: number | null
  initialComment?: string
  stafferId: string
  businessId: string
  isHocProcessing?: boolean
  isStafferApproval?: boolean
  shiftId: string
  timeStart?: firebase.firestore.Timestamp
  timeEnd?: firebase.firestore.Timestamp
  onClose?: () => void
  onGoBack?: () => void
  onRatingChange?: (rating?: number | null, comment?: string, canSaveRating?: boolean) => any
  isNewRating?: boolean
  approveShift?: () => Promise<boolean>
  job: JobType | JobTypeWithDistance
  business?: BusinessType
  onResolveCallback?: VoidFunction
}

const COMMENT_REQUIRED_ON = 3

class EditShiftRating extends Component<Props, State> {
  getRatingOptions = () => {
    const { onResolveCallback } = this.props
    if (!onResolveCallback) {
      return [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
      ]
    }
    return [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 0, label: 'No rating' },
    ]
  }
  constructor(props: Props) {
    super(props)
    const { currentRating, currentComment, initialRating, initialComment } = props
    // Initial values
    const ratingOptions = this.getRatingOptions()
    const defaultRating = ratingOptions[ratingOptions.length - 1]
    this.state = {
      updatedRating: {
        value: currentRating || initialRating || defaultRating.value,
        label: currentRating || initialRating || defaultRating.label,
      },
      updatedComment: currentComment || initialComment || '',
      isProcessing: false,
      canSaveRating: (currentRating !== undefined && currentRating !== null) || (initialRating !== null && initialRating !== undefined) || defaultRating.value === 0,
    }
  }

  ratingChanged = () => {
    const { onRatingChange } = this.props
    const { updatedRating, updatedComment, canSaveRating } = this.state
    if (onRatingChange) {
      onRatingChange(updatedRating.value, updatedComment || '', canSaveRating)
    }
  }

  // Handle dropdown menu
  handleRatingChange = (selectedOption: RatingOption) => {
    const { updatedComment } = this.state
    let canSaveRating = false
    if (selectedOption && selectedOption.value !== undefined && selectedOption.value !== null) {
      if (selectedOption.value <= COMMENT_REQUIRED_ON && selectedOption.value !== 0) {
        canSaveRating = !!updatedComment
      } else {
        canSaveRating = selectedOption.value !== undefined && selectedOption.value !== null
      }
    }
    this.setState(
      {
        updatedRating: selectedOption,
        canSaveRating,
      },
      this.ratingChanged
    )
  }

  handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { updatedRating } = this.state
    const updatedComment = e.target.value
    let canSaveRating = false
    if (updatedRating.value) {
      if (updatedRating.value <= COMMENT_REQUIRED_ON) {
        canSaveRating = !!updatedComment
      } else {
        canSaveRating = true
      }
    }
    this.setState(
      {
        updatedComment,
        canSaveRating,
      },
      this.ratingChanged
    )
  }

  // Save changes method
  onSaveChangesHandler = async () => {
    const { updatedRating, updatedComment, canSaveRating } = this.state

    if (this.props.approveShift) {
      await this.props.approveShift()
    }
    const { stafferId, businessId, shiftId, timeStart, timeEnd, onClose, job, onResolveCallback } = this.props

    const successMessage = this.props.approveShift
      ? `Staffer was successfully approved${canSaveRating || updatedRating.value !== 0 ? ' and rated' : ''}`
      : "Staffer's rating was successfully changed"

    try {
      this.setState({ isProcessing: true })
      if (canSaveRating && updatedRating.value) {
        if (updatedRating.value <= COMMENT_REQUIRED_ON && !updatedComment) {
          throw Error(`Ratings of ${COMMENT_REQUIRED_ON} or less require a comment.`)
        }
        await updateStafferRating(
          stafferId,
          businessId,
          job.id,
          shiftId,
          timeStart,
          timeEnd,
          updatedRating.value,
          updatedComment || '',
          job.businessName,
          job.jobType
        )
        toast.success(successMessage)
        this.props.approveShift && onResolveCallback && onResolveCallback()
      } else {
        toast.success(successMessage)
        this.props.approveShift && onResolveCallback && onResolveCallback()
      }
    } catch (error) {
      console.error(error)
      errorToast((error as Error).message)
    } finally {
      this.setState({ isProcessing: false })
      if (onClose) {
        onClose()
      }
    }
  }

  // Delete comment/rating method
  onDeleteHandler = async (fieldToDelete: string) => {
    const { stafferId, businessId, shiftId, onClose } = this.props

    try {
      const selectedRatingsRef = await firebase
        .firestore()
        .collection('staffers')
        .doc(stafferId)
        .collection('ratings')
        .doc(businessId)

      const selectedRatingsDoc = await selectedRatingsRef.get()
      const selectedRatingsData = selectedRatingsDoc.data()

      let updatedRatings = []

      if (selectedRatingsData) {
        // Store and update current ratings
        if (fieldToDelete === 'comment') {
          updatedRatings = selectedRatingsData.ratings.map((rating: Record<string, string>) => {
            if (rating.shiftId === shiftId) {
              return {
                ...rating,
                comment: '',
              }
            }
            return rating
          })
        } else if (fieldToDelete === 'rating') {
          updatedRatings = selectedRatingsData.ratings.map((rating: Record<string, string>) => {
            if (rating.shiftId === shiftId) {
              return {
                ...rating,
                value: null,
              }
            }
            return rating
          })
        }
      }

      try {
        this.setState({ isProcessing: true })
        await selectedRatingsRef.update({
          ratings: updatedRatings,
        })
        if (fieldToDelete === 'rating') {
          this.setState({
            updatedRating: {
              value: null,
              label: 'Unrated',
            },
          })
        }
        if (fieldToDelete === 'comment') {
          this.setState({ updatedComment: '' })
        }
        toast.success(`Shift ${fieldToDelete} was deleted successfully.`)
      } catch (error) {
        errorToast((error as Error).message)
        console.warn(error)
      } finally {
        this.setState({ isProcessing: false })
        if (onClose) {
          onClose()
        }
      }
    } catch (error) {
      console.warn(error)
    }
  }

  render() {
    const {
      onClose,
      initialComment,
      initialRating,
      isHocProcessing,
      isNewRating,
      isStafferApproval,
      onGoBack,
      approveShift,
      onRatingChange,
      business,
    } = this.props

    const { isProcessing, updatedRating, updatedComment, canSaveRating } = this.state

    if (isHocProcessing) {
      return null
    }

    if (isProcessing || !business) {
      return (
        <div className={styles.loaderWrapper}>
          <BeatLoader />
        </div>
      )
    }

    const ratingOptions = this.getRatingOptions()
    console.log('canSaveRating', { canSaveRating, updatedRating })

    return (
      <Grid container item alignItems="center" justify="center">
        {!isStafferApproval && isNewRating && (
          <ModalHeader close={onClose} goBack={onGoBack}>
            Add shift rating/comment
          </ModalHeader>
        )}
        <Grid container item spacing={3} justify="center">
          {!business.ratingDisabled && (
            <>
              <Grid item xs={12} lg={4} md={4} sm={8}>
                <Box mx={2} textAlign="center">
                  <Box m={1}>
                    <Typography component="h2">{isNewRating ? 'Rating:' : 'Edit rating:'}</Typography>
                  </Box>
                  <Select
                    menuPortalTarget={global.document.querySelector('body')}
                    styles={{ menuPortal: (base: Record<string, string>) => ({ ...base, zIndex: 2001 }) }}
                    value={updatedRating}
                    options={ratingOptions}
                    onChange={this.handleRatingChange}
                  />
                  {initialRating && initialRating === updatedRating.value && (
                    <Button
                      variant="contained"
                      className={muiButtonStyles.danger}
                      onClick={() => this.onDeleteHandler('rating')}
                    >
                      Delete Rating
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} lg={4} md={4} sm={12}>
                <Box mx={3} textAlign="center">
                  <Box m={1}>
                    <Typography component="h2">{isNewRating ? 'Comment:' : 'Edit comment:'}</Typography>
                  </Box>
                  <TextField
                    type="text"
                    placeholder={updatedComment || 'No comment'}
                    name="comment"
                    onChange={this.handleCommentChange}
                    value={updatedComment}
                    onBlur={this.ratingChanged}
                  />
                  <Typography variant="subtitle2">This is only visible to other businesses</Typography>
                  {initialComment && initialComment === updatedComment && (
                    <Button
                      variant="contained"
                      className={muiButtonStyles.danger}
                      onClick={() => this.onDeleteHandler('comment')}
                    >
                      Delete Comment
                    </Button>
                  )}
                </Box>
              </Grid>
            </>
          )}
          {
            // || 5 to ensure the condition fails if value is not yet defined
            !canSaveRating && (updatedRating.value || 5) <= 3 && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {`Ratings of ${COMMENT_REQUIRED_ON} or less require a comment`}
                </Typography>
              </Grid>
            )
          }
        </Grid>
        {!onRatingChange && (
          <Box my={4} textAlign="center">
            <Button className={muiButtonStyles.default} color="default" variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className={muiButtonStyles.default}
              color="primary"
              onClick={this.onSaveChangesHandler}
              variant="contained"
              disabled={!canSaveRating}
            >
              {approveShift ? 'Finish approving' : 'Save rating'}
            </Button>
          </Box>
        )}
      </Grid>
    )
  }
}

export default EditShiftRating
