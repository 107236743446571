import { LinearProgress, Modal } from '@mui/material'
import moment from 'moment'
import type { FC } from 'react'
import type { StafferRatingsType } from '../../../../src/types/staffer'
import BusinessPlaceholder from '../../img/BusinessPlaceholder.png'
import StarIcon from '../../img/StarIcon.svg'
import { business as businessSchema } from '../../staffers/qman/business'
import styles from './StafferProfileModal.module.css'
import { StafferProfileModalHeader } from './StafferProfileModalHeader'
import { getJobTypeCount, getRatingsProgressByValue } from './helpers'

type RatingValues = '5' | '4' | '3' | '2' | '1'

type Props = {
  isOpen: boolean
  onClose: () => void
  ratingsCount: number
  ratingsTotal: number
  stafferRatings: StafferRatingsType[]
}

const StafferShiftDetailsModal: FC<Props> = ({ isOpen, onClose, ratingsCount, ratingsTotal, stafferRatings }) => {
  const businesses = stafferRatings?.map((r) => businessSchema.get('businessById', [r.businessId])) || []
  const ratingsByValue = getRatingsProgressByValue(stafferRatings || [], ratingsCount)
  const jobTypeByCount = getJobTypeCount(stafferRatings || [])
  const ratingValues = Object.keys(ratingsByValue).sort((a, b) => parseInt(b) - parseInt(a)) as RatingValues[]
  const ratingText = ratingsCount ? (ratingsTotal / ratingsCount).toFixed(1) : 0

  return (
    <Modal
      hideBackdrop
      open={isOpen}
      onClose={onClose}
      style={{ backgroundColor: 'rgba(0,0,0,0.35)', zIndex: 30 }}
      className={styles.modal}
    >
      <div className={styles.shiftsModal}>
        <StafferProfileModalHeader title="Reviews" onClose={onClose} />
        <div className={styles.shiftsContent}>
          <div className={styles.shiftsContainer}>
            <div className={styles.shiftsRowHeader}>
              <div className={styles.shiftsRatingContainer}>
                <img src={StarIcon} alt="Rating icon" className={styles.shiftsRatingIcon} />
                <div className={styles.shiftsContainerColumn}>
                  <span className={styles.title}>{ratingText} rating</span>
                  <span className={styles.reviewsText}>{ratingsCount} reviews</span>
                </div>
              </div>
              <div className={styles.shiftsContainerColumn}>
                {ratingValues.map((rating, index) => (
                  <div key={index} className={styles.shiftsRow}>
                    <span>{rating}</span>
                    <LinearProgress
                      variant="determinate"
                      value={ratingsByValue[rating]}
                      sx={{
                        backgroundColor: '#D4D4D4',
                        borderRadius: 2,
                        height: 8,
                        width: '26rem',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: '#26C64B',
                          height: 8,
                          borderRadius: 2,
                        },
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.shiftsColumnHeader}>
              <span className={styles.title}>Jobs done</span>
              {jobTypeByCount ? (
                <div className={styles.shiftsRow}>
                  {Object.keys(jobTypeByCount).map((jobType, index) => (
                    <span key={index} className={styles.shiftsChip}>
                      <span className={styles.shiftsTitle}>{jobType}</span>
                      <span className={styles.greyText}>·</span>
                      <span className={styles.greyText}>{jobTypeByCount[jobType]} jobs</span>
                    </span>
                  ))}
                </div>
              ) : (
                <span className={styles.emptySection} style={{ marginBottom: 20 }}>
                  No reviews found
                </span>
              )}
            </div>
            {!!stafferRatings.length && (
              <div className={styles.shiftsReviewContent}>
                {stafferRatings &&
                  businesses.length > 0 &&
                  stafferRatings.map((businessRating, bIndex) => {
                    const business = businesses.find((b) => b?.id === businessRating.businessId)
                    return business ? (
                      businessRating.ratings.map((rating, index) => (
                        <div
                          key={index}
                          className={styles.shiftsReviewColumn}
                          style={
                            index !== 0 || bIndex !== 0
                              ? { borderTop: '1px solid #E5E5E5', paddingTop: 24 }
                              : { borderTop: 'none' }
                          }
                        >
                          <div className={styles.shiftfsReviewRow}>
                            <div className={styles.shiftsRow}>
                              <img
                                src={business?.coverImageUrl || BusinessPlaceholder}
                                alt="Business cover"
                                className={styles.shiftsBusinessCover}
                              />
                              <div className={styles.shiftsColumn}>
                                <span className={styles.shiftsTitle}>{business?.businessName}</span>
                                <div className={styles.shiftfsReviewRow}>
                                  <span className={styles.greenTextSmall}>{rating.jobType}</span>
                                  <span className={styles.greyText}>·</span>
                                  <span className={styles.greyText}>{business.businessType}</span>
                                  <span className={styles.greyText}>·</span>
                                  {rating.timeStart && (
                                    <span className={styles.greyText}>
                                      {moment(rating.timeStart.toMillis()).format('D MMM, YYYY')}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={styles.shiftsChip} style={{ alignSelf: 'flex-start' }}>
                              <img src={StarIcon} alt="rating icon" />
                              <span className={styles.shiftsTitle}>{rating.value}</span>
                            </div>
                          </div>
                          {rating.comment ? <p>{rating.comment}</p> : <p className={styles.emptySection}>No comment</p>}
                        </div>
                      ))
                    ) : (
                      <span className={styles.emptySection}>Business not found</span>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StafferShiftDetailsModal
