import { Link } from '@material-ui/core'
import ISO6391 from 'iso-639-1'
import { useState, type FC } from 'react'
import type { StafferDocumentsType, StafferType } from '../../../../src/types/staffer'
import VerifiedUserIcon from '../../img/VerifiedUserIcon.svg'
import FileIcon from '../../img/algoliaIcons/FileIcon.svg'
import PdfFile from '../../img/file-icons/pdf-updated.svg'
import Button from '../mui/redesignedComponents/Button'
import StafferProfileExperienceCard from './StafferProfileExperienceCard'
import styles from './StafferProfileModal.module.css'

const DOCUMENT_TYPES = {
  drivinglicense: 'Driving license',
  passport: 'Passport',
  taxcard: 'TaxCard',
  hmskurs: 'HMS kurs',
  ikmat: 'IK-Mat',
  identity: 'Identity',
} as const

type DocumentType = keyof typeof DOCUMENT_TYPES
const documents = Object.keys(DOCUMENT_TYPES) as DocumentType[]
const VERIFIED_DOCUMENTS: DocumentType[] = ['passport', 'taxcard', 'identity']
const PAGINATION_LIMIT = 5

const ChipJobVariant = ({ variant }: { variant?: string }) => {
  if (!variant) {
    return <div>None</div>
  }
  let jobVariant = ''
  if (variant === 'SEASONAL') {
    jobVariant = 'Seasonal'
  }
  if (variant === 'EXTRA_WORK') {
    jobVariant = 'Extra'
  }
  if (variant === 'PART_TIME') {
    jobVariant = 'Part-time'
  }
  if (variant === 'FULL_TIME') {
    jobVariant = 'Full-time'
  }
  return <div className={styles.chip}>{jobVariant}</div>
}

type ChipDocsProps = {
  stafferDocuments: StafferDocumentsType
  documentType: DocumentType
  hasVerifiedDocuments?: boolean
}

type Props = {
  staffer: StafferType
  stafferDocuments: StafferDocumentsType | null
}

const ChipDocs = ({ stafferDocuments, documentType, hasVerifiedDocuments }: ChipDocsProps) => (
  <div style={{ display: 'flex' }}>
    {stafferDocuments &&
      stafferDocuments[documentType] &&
      stafferDocuments[documentType]?.map((_, index) => (
        <div className={styles.chip} key={index}>
          <img src={FileIcon} alt="Document" className={styles.smallIcon} />
          <span>{DOCUMENT_TYPES[documentType]}</span>
          {hasVerifiedDocuments && (VERIFIED_DOCUMENTS.includes(documentType) || documentType === 'drivinglicense') && (
            <img src={VerifiedUserIcon} alt="Document" className={styles.xsIcon} />
          )}
        </div>
      ))}
  </div>
)

const StafferProfileContent: FC<Props> = ({ staffer, stafferDocuments }) => {
  const { experiences, nameFirst, languages, bankId, documentsVerified, description, wantedJobVariants } = staffer
  const [paginationIndex, setPaginationIndex] = useState(PAGINATION_LIMIT)

  const sortedExperiences = (experiences || []).sort((a, b) => b.startDate.toMillis() - a.startDate.toMillis())
  const formattedCvName = nameFirst[nameFirst.length - 1] === 's' ? `${nameFirst}'` : `${nameFirst}'s`
  const stafferLanguages = ISO6391.getLanguages(languages).map((language) => language.name)
  const stafferVerified = !!bankId || !!documentsVerified

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.contentColumnWrapper}>
        <div className={styles.contentRow}>
          <span className={styles.title}>Previous experience</span>
          <div className={styles.chipSmall}>{sortedExperiences.length || 0} Jobs</div>
        </div>
        {experiences && experiences.length > 0 ? (
          <div className={styles.contentColumn}>
            {sortedExperiences.map(
              (
                { startDate, endDate, jobCompany, jobTitle, jobType, reference, referenceEmail, phone: refPhone },
                index
              ) =>
                index < paginationIndex && (
                  <StafferProfileExperienceCard
                    key={index}
                    startDate={startDate}
                    endDate={endDate}
                    jobTitle={jobTitle}
                    jobCompany={jobCompany}
                    jobType={jobType}
                    reference={reference}
                    referenceEmail={referenceEmail}
                    referencePhone={refPhone}
                    style={{ borderTop: index === 0 ? 'none' : '1px solid #E5E5E5' }}
                  />
                )
            )}
            {experiences.length > PAGINATION_LIMIT && paginationIndex < experiences.length && (
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: 16 }}
                onClick={() => setPaginationIndex(paginationIndex + PAGINATION_LIMIT)}
              >
                Show more
              </Button>
            )}
          </div>
        ) : (
          <div className={styles.emptySection}>No previous experience</div>
        )}
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>{formattedCvName} CV</span>
        {stafferDocuments?.cv && stafferDocuments.cv.length ? (
          stafferDocuments.cv.map((cv, index) => (
            <div key={index} className={styles.cvContainer}>
              <img src={PdfFile} alt="PDF File" />
              <span>Check out {formattedCvName} CV</span>
              <span className={styles.divider} />
              <Link href={cv.url} target="_blank" className={styles.linkBtnSmall}>
                View detail
              </Link>
            </div>
          ))
        ) : (
          <div className={styles.emptySection}>No CV uploaded</div>
        )}
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>About me</span>
        <div className={styles.contentRow}>
          {description ? (
            <span className={styles.greyTextLarge}>{description}</span>
          ) : (
            <div className={styles.emptySection}>No description</div>
          )}
        </div>
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>Documents</span>
        <div className={styles.contentRow}>
          {stafferDocuments ? (
            documents.map(
              (documentType, index) =>
                stafferDocuments[documentType] && (
                  <ChipDocs
                    stafferDocuments={stafferDocuments}
                    documentType={documentType}
                    hasVerifiedDocuments={stafferVerified}
                    key={index}
                  />
                )
            )
          ) : (
            <div className={styles.emptySection}>No documents</div>
          )}
        </div>
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>Languages</span>
        {stafferLanguages.length > 0 ? (
          <div className={styles.contentRow}>
            {stafferLanguages.map((language) => (
              <div className={styles.chip} key={language}>
                <span>{language}</span>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.emptySection}>No selected languages</div>
        )}
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>What type of work am I looking for?</span>
        <div className={styles.contentRow}>
          {wantedJobVariants && wantedJobVariants.length > 0 ? (
            wantedJobVariants.map((wantedJobVariant, index) => (
              <ChipJobVariant key={index} variant={wantedJobVariant} />
            ))
          ) : (
            <div className={styles.emptySection}>No selected type</div>
          )}
        </div>
      </div>
      <div className={styles.contentColumnWrapper} style={{ border: 'none' }}>
        <span className={styles.title}>Video presentation</span>
        <div className={styles.emptySection}>No video presentation uploaded</div>
      </div>
    </div>
  )
}

export default StafferProfileContent
