import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import type { FC } from 'react'
import Button from '../mui/redesignedComponents/Button'
import styles from './StafferProfileModal.module.css'

type Props = {
  onMessage?: () => void
  onPagination?: (dir: 'next' | 'prev') => void
  isProcessing: boolean
}

const StafferProfileFooter: FC<Props> = ({ onMessage, onPagination, isProcessing }) => (
  <div className={styles.footer}>
    {onPagination && (
      <Button variant="outlined" className={styles.buttonFooter} onClick={() => onPagination('prev')}>
        <ChevronLeft />
        Previous applicant
      </Button>
    )}
    <div className={styles.contentRow} style={{ justifyContent: 'flex-end', gap: 10 }}>
      {onMessage && (
        <Button variant="outlined" className={styles.buttonFooter} onClick={onMessage} disabled={isProcessing}>
          {isProcessing ? 'Creating chat' : 'Message'}
        </Button>
      )}
      {onPagination && (
        <Button
          variant="contained"
          color="primary"
          className={styles.buttonFooter}
          onClick={() => onPagination('next')}
        >
          Next applicant
          <ChevronRight />
        </Button>
      )}
    </div>
  </div>
)

export default StafferProfileFooter
