import { MenuItem } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import moment from 'moment'
import type { ChangeEvent, FC } from 'react'
import ImageZoom from 'react-medium-image-zoom'
import type { JobAdStafferType } from '../../../../src/types/jobAds'
import type { EnumJobStafferStatus } from '../../../../src/types/jobs'
import type { StafferType } from '../../../../src/types/staffer'
import StarIcon from '../../img/StarIcon.svg'
import VerifiedUserIcon from '../../img/VerifiedUserIcon.svg'
import InternalTempIcon from '../../img/InternalTempIcon.svg'
import Select from '../mui/Select'
import Button from '../mui/redesignedComponents/Button'
import StafferLinearProgress from './StafferLinearProgress'
import styles from './StafferProfileModal.module.css'

type Props = {
  staffer: StafferType
  isProcessing: boolean
  jobAdStaffer?: JobAdStafferType
  onSeePreviousShifts: () => void
  setFitness?: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  onAcceptStaffer?: () => void
  onDenyStaffer?: () => void
  onToggleNote?: () => void
  companyNote?: string
  jobStafferStatus?: EnumJobStafferStatus
  isInternalTemp: boolean
}

const StafferProfileSidebar: FC<Props> = ({
  staffer,
  isProcessing,
  setFitness,
  jobAdStaffer,
  onSeePreviousShifts,
  onAcceptStaffer,
  onDenyStaffer,
  onToggleNote,
  companyNote,
  jobStafferStatus,
  isInternalTemp,
}) => {
  const stafferVerified = !!staffer.bankId || !!staffer.documentsVerified
  const ratingText = staffer.ratingsCount ? (staffer.ratingsTotal / staffer.ratingsCount).toFixed(1) : '-'
  const totalShiftsCompleted = staffer.totalShifts ?? 0
  const punctualityShiftsCompleted = staffer.punctualityTotalShifts ?? 0
  const age = staffer.birthday ? `${moment().diff(moment(staffer.birthday.toMillis()), 'years')} years` : '-'
  const hometown = staffer.region ? `${staffer.region?.countryName}, ${staffer.region?.cityName}` : '-'
  const email = staffer.email ? staffer.email.toLowerCase() : '-'
  const phone = staffer.phone || '-'

  return (
    <div className={styles.sidebar}>
      <div className={styles.avatarWrapper}>
        <div style={{ position: 'relative' }}>
          <ImageZoom
            image={{
              className: styles.avatar,
              src: staffer.photoUrl || '',
              alt: `Avatar of user ${staffer.nameFirst} ${staffer.nameLast}`,
            }}
            zoomImage={{
              src: staffer.photoUrl || '',
            }}
            shouldHandleZoom={() => Boolean(staffer.photoUrl || '')}
            defaultStyles={{
              overlay: { background: 'rgba(240, 240, 240, 0.9)' },
            }}
          />
          {(stafferVerified || isInternalTemp) && <img src={isInternalTemp ? InternalTempIcon : VerifiedUserIcon} alt="user status" className={styles.verifiedUser} />}
        </div>
        <div className={styles.ratingWrapper}>
          <span className={styles.name}>{staffer.nameFirst}</span>
          <img src={StarIcon} className={styles.ratingIcon} />
          <span className={styles.ratingText}>{ratingText}</span>
        </div>
        <div className={styles.shiftDetails}>
          <span>{totalShiftsCompleted} shifts</span>
          <Button variant="text" className={styles.linkBtn} onClick={onSeePreviousShifts}>
            See previous shifts
          </Button>
        </div>
        {staffer.bankId && (
          <div className={styles.verifiedWrapper}>
            <img src={VerifiedUserIcon} alt="verified user" className={styles.verifiedIcon} />
            <span>User has been verified by BankID</span>
          </div>
        )}
      </div>
      <div className={styles.columnWrapper} style={{ rowGap: 8 }}>
        {jobStafferStatus === 'requested' && onAcceptStaffer && onDenyStaffer && (
          <div className={styles.jobButtonsWrapper}>
            <Button
              variant="outlined"
              className={styles.jobButton}
              onClick={() => onAcceptStaffer()}
              disabled={isProcessing}
            >
              <div className={styles.jobButtonContent}>
                <ThumbUpOutlinedIcon className={styles.acceptCandidateIcon} />
                <span>{isProcessing ? 'is Processing...' : 'Accept'}</span>
              </div>
            </Button>
            <Button
              variant="outlined"
              className={styles.jobButton}
              onClick={() => onDenyStaffer()}
              disabled={isProcessing}
            >
              <div className={styles.jobButtonContent}>
                <ThumbDownOutlinedIcon className={styles.declineCandidateIcon} />
                <span>{isProcessing ? 'is Processing...' : 'Deny'}</span>
              </div>
            </Button>
          </div>
        )}
        {/* {punctualityShiftsCompleted > 0 && (
          <StafferLinearProgress
            value={staffer.lateness}
            maxValue={punctualityShiftsCompleted}
            label="Punctuality"
            type="successRate"
          />
        )}
        {!jobAdStaffer && (staffer.cancellations > 0 || totalShiftsCompleted > 0) && (
          <StafferLinearProgress
            value={staffer.cancellations}
            maxValue={totalShiftsCompleted}
            label="Cancellation"
            type="failureRate"
          />
        )} */}
        <div className={styles.personalInfoWrapper}>
          <span className={styles.title}>Personal information</span>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Hometown</span>
            <span className={styles.infoText}>{hometown}</span>
          </div>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Age</span>
            <span className={styles.infoText}>{age}</span>
          </div>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Email</span>
            <span className={styles.infoText}>{email}</span>
          </div>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Phone number</span>
            <span className={styles.infoText}>{phone}</span>
          </div>
        </div>
        {jobAdStaffer && setFitness && (
          <>
            {/* <Button variant="outlined" className={styles.interactiveBtn}>
              <div className={styles.contentRow}>
                <div className={styles.contentColumn}>
                  <span className={styles.textBold}>Questionaire</span>
                  <div className={styles.greenTextSmall}>5 answers</div>
                </div>
                <ChevronRight className={styles.btnChevron} />
              </div>
            </Button> */}
            <div className={styles.contantRow}>
              <Select
                className={styles.interactiveBtn}
                style={{ borderRadius: 8, fontSize: 16, fontWeight: 600, padding: '10px 12px' }}
                IconComponent={() => <ChevronRight className={styles.btnChevronDown} />}
                onChange={setFitness}
                placeholder="Good or not fit?"
                displayEmpty
                value={jobAdStaffer.status === 'new' ? 'new' : jobAdStaffer.fitness}
                disabled={isProcessing}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 8,
                      fontSize: 16,
                      fontWeight: 400,
                      // marginTop: 55,
                    },
                  },
                }}
              >
                {!jobAdStaffer.fitness && (
                  <MenuItem dense value="new">
                    Good or not fit?
                  </MenuItem>
                )}
                <MenuItem dense value="good">
                  <div className={styles.goodFit} />
                  &emsp; Good fit
                </MenuItem>
                <MenuItem dense value="maybe">
                  <div className={styles.maybeAFit} />
                  &emsp; Maybe a fit
                </MenuItem>
                <MenuItem dense value="not">
                  <div className={styles.notFit} />
                  &emsp; Not a fit
                </MenuItem>
                {jobAdStaffer.fitness === 'not' && !jobAdStaffer.rejectionMessage && (
                  <MenuItem dense value="send-rejection">
                    <div className={styles.notFit} />
                    &emsp; Send rejection letter
                  </MenuItem>
                )}
              </Select>
            </div>
            <Button
              variant="outlined"
              className={styles.addNoteBtn}
              onClick={() => {
                onToggleNote && onToggleNote()
              }}
            >
              {companyNote ? 'Edit' : 'Add'} note
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default StafferProfileSidebar
