import { Modal } from '@mui/material'
import { getDoc, updateDoc } from 'firebase/firestore'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import type { JobAdStafferType, JobAdType } from '../../../../src/types/jobAds'
import type { StafferType } from '../../../../src/types/staffer'
import { getJobAdStafferById } from '../../staffers/api/getters/jobs'
import CustomButton, { ButtonTypes } from '../button/Button'
import ModalHeader from '../mui/ModalHeader'
import TextInputField from '../textInputField/TextInputField'
import styles from './StafferNoteModal.module.css'

interface StafferNoteType {
  isOpen: boolean
  onClose: () => void
  initialNote: string
  staffer: StafferType
  jobAd: JobAdType
  jobAdStaffer?: JobAdStafferType
}

const StafferNoteModal: FC<StafferNoteType> = ({ isOpen, onClose, initialNote, staffer, jobAd, jobAdStaffer }) => {
  const [note, setNote] = useState(initialNote)

  const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value)
  }

  const handleOnNote = async (type: 'add' | 'remove', _note?: string) => {
    const jobAdStaffers = getJobAdStafferById(jobAd.id, staffer.userId)
    const jobAdStafferDoc = await getDoc(jobAdStaffers)
    const jobAdStafferData = jobAdStafferDoc.data()
    if (type === 'add' && note) {
      try {
        updateDoc(jobAdStaffers, {
          ...jobAdStafferData,
          companyNote: _note,
        })
        onClose()
        return toast.success(jobAdStaffer?.companyNote ? 'Note edited successfully' : 'Note added successfully!')
      } catch (error) {
        return toast.error('Cannot add note')
      }
    }
    if (type === 'remove') {
      try {
        updateDoc(jobAdStaffers, {
          ...jobAdStafferData,
          companyNote: '',
        })
        onClose()
        setNote('')
        return toast.success('Note removed successfully!')
      } catch (error) {
        return toast.error('Cannot remove note')
      }
    }
    return toast.error('Note cannot be empty!')
  }

  return (
    <Modal
      hideBackdrop
      open={isOpen}
      onClose={onClose}
      style={{ backgroundColor: 'rgba(0,0,0,0.35)', zIndex: 30 }}
      className={styles.modal}
    >
      <div className={styles.container}>
        <ModalHeader close={onClose}>
          <span>{initialNote ? 'Edit' : 'Add'} Staffer Note</span>
        </ModalHeader>
        <div className={styles.noteFieldWrapper}>
          <TextInputField value={note} className={styles.noteField} onChange={handleNoteChange} multiline rows={10} />
          <div className={styles.noteButtonsWrapper}>
            <CustomButton
              buttonType={ButtonTypes.BUTTON_RED}
              onClick={() => handleOnNote('remove')}
              title="Remove Note"
            />
            <CustomButton
              buttonType={ButtonTypes.BUTTON_GREEN}
              onClick={() => handleOnNote('add', note)}
              title={initialNote ? 'Save Note' : 'Add Note'}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StafferNoteModal
