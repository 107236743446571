import type { StafferRatingsType } from '../../../../src/types/staffer'

export const getRatingsProgressByValue = (stafferRatings: StafferRatingsType[], ratingsCount: number) => {
  if (!stafferRatings || !ratingsCount) {
    return {
      '5': 0,
      '4': 0,
      '3': 0,
      '2': 0,
      '1': 0,
    }
  }
  const ratingValues = stafferRatings.map((b) => b.ratings.map((r) => r.value)).flat()
  return {
    '5': (ratingValues.filter((r) => r === 5).length / ratingsCount) * 100,
    '4': (ratingValues.filter((r) => r === 4).length / ratingsCount) * 100,
    '3': (ratingValues.filter((r) => r === 3).length / ratingsCount) * 100,
    '2': (ratingValues.filter((r) => r === 2).length / ratingsCount) * 100,
    '1': (ratingValues.filter((r) => r === 1).length / ratingsCount) * 100,
  }
}

export const getJobTypeCount = (stafferRatings: StafferRatingsType[]) => {
  if (!stafferRatings.length) {
    return null
  }
  const jobTypesByValue: { [key: string]: number } = {}
  stafferRatings.forEach((b) => {
    b.ratings.forEach((r) => {
      const jobType = String(r.jobType)
      if (jobTypesByValue[jobType]) {
        jobTypesByValue[jobType]++
      } else {
        jobTypesByValue[jobType] = 1
      }
    })
  })
  return jobTypesByValue
}
