import { Modal } from '@material-ui/core'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'
import { BeatLoader } from 'react-spinners'
import type { JobAdStafferType, JobAdType } from '../../../../src/types/jobAds'
import type { EnumJobStafferStatus } from '../../../../src/types/jobs'
import type { StafferType } from '../../../../src/types/staffer'
import { staffers as staffersSchema } from '../../staffers/qman/staffers'
import StafferNoteModal from './StafferNoteModal'
import StafferProfileContent from './StafferProfileContent'
import StafferProfileFooter from './StafferProfileFooter'
import styles from './StafferProfileModal.module.css'
import { StafferProfileModalHeader } from './StafferProfileModalHeader'
import StafferProfileSidebar from './StafferProfileSidebar'
import StafferShiftDetailsModal from './StafferShiftDetailsModal'
import { GroupInternalTempType } from '../../../../src/types/groups'

type Props = {
  isOpen: boolean
  onClose: () => void
  staffer: StafferType
  jobAd?: JobAdType
  jobAdStaffer?: JobAdStafferType
  isProcessing: boolean
  onMessage?: () => void
  setFitness?: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  onAcceptStaffer?: () => void
  onDenyStaffer?: () => void
  onPagination?: (dir: 'next' | 'prev') => void
  companyNote?: string
  jobStafferStatus?: EnumJobStafferStatus
  stafferInternalTemp: GroupInternalTempType | null
}

const StafferProfileModal: FC<Props> = ({
  isOpen,
  onClose,
  staffer,
  jobAd,
  jobAdStaffer,
  isProcessing,
  setFitness,
  onMessage,
  onAcceptStaffer,
  onDenyStaffer,
  onPagination,
  companyNote,
  jobStafferStatus,
  stafferInternalTemp
}) => {
  const [isShiftsModalOpen, setIsShiftsModalOpen] = useState(false)
  const [isStafferNoteModalOpen, setIsStafferNoteModalOpen] = useState(false)

  // Fetch staffer data
  const stafferDocuments = staffersSchema.get('stafferDocumentsById', [staffer.userId]) || null
  const stafferRatings = staffersSchema.get('stafferRatings', [staffer.userId])
  const isInternalTemp = !!stafferInternalTemp

  return (
    <Modal
      hideBackdrop
      open={isOpen}
      style={{ backgroundColor: 'rgba(0,0,0,0.35)', zIndex: 20 }}
      className={styles.modal}
    >
      {stafferDocuments === undefined || stafferRatings === undefined ? (
        <BeatLoader />
      ) : (
        <>
          <div className={styles.container}>
            <StafferProfileModalHeader title="User detail" onClose={onClose} />
            <div className={styles.content}>
              <StafferProfileSidebar
                staffer={staffer}
                isInternalTemp={isInternalTemp}
                isProcessing={isProcessing}
                setFitness={setFitness}
                jobAdStaffer={jobAdStaffer}
                onSeePreviousShifts={() => setIsShiftsModalOpen(true)}
                onAcceptStaffer={onAcceptStaffer}
                onDenyStaffer={onDenyStaffer}
                onToggleNote={() => {
                  setIsStafferNoteModalOpen(true)
                }}
                companyNote={companyNote}
                jobStafferStatus={jobStafferStatus}
              />
              <StafferProfileContent staffer={staffer} stafferDocuments={stafferDocuments} />
            </div>
            {(onMessage || onPagination) && (
              <StafferProfileFooter onMessage={onMessage} isProcessing={isProcessing} onPagination={onPagination} />
            )}
          </div>
          {isShiftsModalOpen && (
            <StafferShiftDetailsModal
              isOpen={isShiftsModalOpen}
              onClose={() => setIsShiftsModalOpen(false)}
              ratingsCount={staffer.ratingsCount}
              ratingsTotal={staffer.ratingsTotal}
              stafferRatings={stafferRatings}
            />
          )}
          {isStafferNoteModalOpen && jobAd && jobAdStaffer && (
            <StafferNoteModal
              isOpen={isStafferNoteModalOpen}
              onClose={() => setIsStafferNoteModalOpen(false)}
              initialNote={companyNote ?? ''}
              staffer={staffer}
              jobAd={jobAd}
              jobAdStaffer={jobAdStaffer}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default StafferProfileModal
