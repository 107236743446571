import { query, schema } from 'qman'
import {
  getAllGroups,
  getGroupById,
  getGroupByIdPromise,
  getGroupCustomJobTypes,
  getGroupJobTypesById,
  getGroupStaffer,
  getGroupStaffers,
  getGroupStaffersPermissions,
  getGroupsByBusinessId,
  getGroupsWithBusiness,
  getIsGroupStaffer,
  getGroupInternalTemps,
  getGroupInternalTempsByBusinessId,
  getGroupInternalTempsByStafferId,
} from '../api/getters/groups'
import { collectionFn, documentFn, promiseFn, queryFn } from './schemaFunctions'

export const groups = schema(
  'groups',
  query('groupById', getGroupById, documentFn),
  query('groupsByBusinessId', getGroupsByBusinessId, queryFn),
  query('groupByIdPromise', getGroupByIdPromise, promiseFn),
  query('allGroups', getAllGroups, collectionFn),
  query('groupJobTypesById', getGroupJobTypesById, documentFn),
  query('groupStaffers', getGroupStaffers, queryFn),
  query('groupStaffersPermissions', getGroupStaffersPermissions, promiseFn),
  query('groupStaffer', getGroupStaffer, documentFn),
  query('isGroupStaffer', getIsGroupStaffer, promiseFn),
  query('groupCustomJobTypes', getGroupCustomJobTypes, documentFn),
  query('groupsWithBusiness', getGroupsWithBusiness, queryFn),
  query('groupInternalTemps', getGroupInternalTemps, collectionFn),
  query('groupInternalTempsByBusinessId', getGroupInternalTempsByBusinessId, queryFn),
  query('groupInternalTempsByStafferId', getGroupInternalTempsByStafferId, queryFn),
)
