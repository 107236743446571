import AwesomeDebouncePromise from 'awesome-debounce-promise'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { ChangeEvent } from 'react'
import React, { Component } from 'react'
// @ts-ignore react-firestore-connect has no @types/ export
import { connectFirestore } from 'react-firestore-connect'
import { BeatLoader } from 'react-spinners'
// @ts-ignore react-phone-input-2 has no @types/ export
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AccountCircle, Money } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import * as R from 'ramda'
import type { CountryData } from 'react-phone-input-2'
import PhoneInput from 'react-phone-input-2'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { BusinessType } from '../../../../src/types/business'
import type { InviteType } from '../../../../src/types/common'
import type { Firestore } from '../../../../src/types/firebase'
import type { GroupJobTypesType } from '../../../../src/types/groups'
import type { ManagerType } from '../../../../src/types/manager'
import type { PermissionsType } from '../../../../src/types/pools'
import type { StafferType } from '../../../../src/types/staffer'
import { TooltipDelays } from '../../constants/tooltips'
import { fixCountryDialCode, standardized } from '../../helpers/phoneNumbers'
import { getCurrencyByBusiness } from '../../helpers/regionSelector'
import { errorToast } from '../../helpers/toast'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import { getBusinessById } from '../../staffers/api/getters/business.legacy'
import { getGroupJobTypesById } from '../../staffers/api/getters/groups.legacy'
import { getManagerById } from '../../staffers/api/getters/managers.legacy'
import { getInvitedStafferByCode } from '../../staffers/api/getters/pools.legacy'
import { getStafferPermissions, getStaffersByPhone } from '../../staffers/api/getters/staffer.legacy'
import Empty from '../mui/Empty'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import PoolPermissionsItem from './EditPermissions/PoolPermissionItem'
import { getJobTypes } from '../../staffers/api/getters/common.legacy'
import { EnumJobType } from '../../../../src/types/jobTypes'

type Props = {
  business?: BusinessType
  businessId: string
  closeModal: () => any
  code?: string
  existingInvite: InviteType | null
  groupId?: string
  groupJobTypes: GroupJobTypesType
  isAlreadyInvited?: boolean
  isExternalHire?: boolean
  loadingCallback: (value: boolean) => void
  stafferInfo?: {
    email?: string
    existingStaffer?: string
    isNewImport?: boolean
    name: string
    phone: string
    stafferId?: string
  }
  manager: ManagerType
  inviteInternalTemp?: boolean
  jobTypes?: {
    id: 'jobTypes'
    values: Array<EnumJobType>
  } | null
}

type State = {
  contractPercentage?: number
  customMessage: string
  customWage?: number
  email: string
  existingStaffer?: string // name of staffer with given phone number already in system (if any)
  hasConfirmedDialog: boolean
  isConfirmationDialogOpen: boolean
  isFormValid: boolean
  isProcessing: boolean
  multipleStaffers: Array<{
    existingStaffer: string
    skillLevels?: Record<string, string> | undefined
    customWage?: number
    contractPercentage?: number
    selectedPositions?: string[]
  } | null> | null
  name: string
  phone: string
  selectedPositions: Array<string>
  sendSms: boolean
  skillLevels: Record<string, string> | undefined
  stafferId: string | null
  useCustomMessage: boolean
} & Partial<StafferType>

class InviteStafferToPool extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { existingInvite, stafferInfo } = props

    const existingStaffer = (stafferInfo && stafferInfo.existingStaffer) || undefined
    const name = (stafferInfo && stafferInfo.name) || (existingInvite && existingInvite.name) || ''
    const phone = (stafferInfo && stafferInfo.phone) || (existingInvite && existingInvite.phone) || ''
    const isFormValid = !!(existingStaffer || name.length > 3) && standardized(phone).length > 9

    this.state = {
      contractPercentage: existingInvite ? existingInvite.contractPercentage : undefined,
      customMessage: '',
      customWage: existingInvite ? existingInvite.customWage : undefined,
      email: (stafferInfo && stafferInfo.email) || (existingInvite && existingInvite.email) || '',
      existingStaffer,
      hasConfirmedDialog: false,
      isConfirmationDialogOpen: false,
      isFormValid,
      isProcessing: false,
      multipleStaffers: null,
      name,
      phone,
      selectedPositions: existingInvite
        ? this.props.inviteInternalTemp
          ? existingInvite.recommendedPositions || []
          : existingInvite.positions
        : [],
      sendSms: false,
      skillLevels: existingInvite && existingInvite.skillLevels ? existingInvite.skillLevels : {},
      stafferId: (stafferInfo && stafferInfo.stafferId) || null,
      useCustomMessage: false,
    }
  }

  toggleConfirmationDialog = () =>
    this.setState((prevState) => ({
      isConfirmationDialogOpen: !prevState.isConfirmationDialogOpen,
    }))

  confirmEmployeePositionsAssignment = () =>
    this.setState(
      {
        hasConfirmedDialog: true,
      },
      async () => {
        this.toggleConfirmationDialog()
        await this.save()
      }
    )

  toggleSendSms = () =>
    this.setState((prevState) => ({
      sendSms: !prevState.sendSms,
    }))

  toggleCustomMessage = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ useCustomMessage: e.target.checked })
  }

  handleCustomMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ customMessage: e.target.value })
  }

  changeName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState(
      {
        name: value,
      },
      this.validate
    )
  }

  changePhone = async (phone: string, countryData: Partial<CountryData>) => {
    const realPhone = fixCountryDialCode(phone, countryData.dialCode)
    if (phone) {
      this.setState(
        {
          phone: realPhone,
        },
        this.validate
      )
      this.checkExistingStafferWithPhone(phone)
    }
  }

  // check whether staffer with this phone is already registered
  checkExistingStafferWithPhone = AwesomeDebouncePromise(async (phone: string) => {
    const { businessId, inviteInternalTemp } = this.props
    const database = firebase.firestore() as Firestore
    const existingStaffers = await getStaffersByPhone(database, standardized(phone)).get()
    if (existingStaffers.docs.length > 0) {
      if (existingStaffers.docs.length === 1) {
        const staffer = existingStaffers.docs[0].data() as StafferType
        const stafferPermissionsDoc = await getStafferPermissions(database, staffer.userId).get()
        const stafferPermissions = stafferPermissionsDoc.data() as PermissionsType | null
        const { nameFirst, nameLast } = staffer
        if (stafferPermissions) {
          const { businessData, businessPermissions } = stafferPermissions
          this.setState(
            {
              existingStaffer: `${nameFirst} ${nameLast}.`,
              skillLevels: (businessData && businessData[businessId] && businessData[businessId].skillLevels) || {},
              customWage:
                (businessData && businessData[businessId] && businessData[businessId].customWage) || undefined,
              contractPercentage:
                (businessData && businessData[businessId] && businessData[businessId].contractPercentage) || undefined,
              selectedPositions: (businessPermissions && businessPermissions[businessId]) || [],
              stafferId: existingStaffers.docs[0].id,
            },
            this.validate
          )
        } else {
          this.setState(
            {
              existingStaffer: `${nameFirst} ${nameLast}.`,
            },
            this.validate
          )
        }
        // Corner case - staffers with same phone (shouldnt be possible anymore, but legacy)
      } else {
        const multipleStaffers = await Promise.all(
          existingStaffers.docs.map(async (doc) => {
            const staffer = doc.data() as StafferType
            const { nameFirst, nameLast } = staffer
            const stafferPermissions = (await getStafferPermissions(database, doc.id)
              .get()
              .then((snap) => snap.data())) as PermissionsType
            if (stafferPermissions) {
              const { businessData, businessPermissions, positions } = stafferPermissions
              return {
                existingStaffer: `${nameFirst} ${nameLast}`,
                skillLevels: (businessData && businessData[businessId] && businessData[businessId].skillLevels) || {},
                customWage:
                  (businessData && businessData[businessId] && businessData[businessId].customWage) || undefined,
                contractPercentage:
                  (businessData && businessData[businessId] && businessData[businessId].contractPercentage) ||
                  undefined,
                selectedPositions: inviteInternalTemp ? positions || [] : (businessPermissions && businessPermissions[businessId]) || [],
                stafferId: doc.id,
              }
            }
            return null
          })
        ).then((result) => result.filter((staffer) => !!staffer))
        if (multipleStaffers.length > 0) {
          toast.warn(`Found multiple staffers with the phone: ${phone}. Please select the one you want to invite`)
          this.setState({
            multipleStaffers,
          })
        } else {
          errorToast(
            'Found multiple profiles, but missing permission docs for all of them. Likely a testing DB inconsistency'
          )
        }
      }
    }
  }, 500)

  validate = () => {
    const { contractPercentage, phone, name, existingStaffer } = this.state
    this.setState({
      isFormValid:
        !!(existingStaffer || name.length > 3) &&
        standardized(phone).length > 9 &&
        ((!contractPercentage && contractPercentage !== 0) || (contractPercentage >= 0 && contractPercentage <= 100)),
    })
  }

  getTooltip = () => {
    const { groupJobTypes } = this.props
    const { phone, name, existingStaffer } = this.state
    if (!existingStaffer && name.length <= 3) {
      return 'Please fill the name first'
    }
    if (standardized(phone).length <= 9) {
      return 'Please fill the phone first'
    }
    const groupPositions = (groupJobTypes && groupJobTypes.values) || []
    if (groupPositions.filter((position) => !position.isArchived).length === 0) {
      return 'You must create at least one custom position to continue'
    }
    return ''
  }

  updateSkillLevel = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name: position, value } = event.target
    this.setState((prevState) => ({
      skillLevels: value
        ? R.assoc(position, value, prevState.skillLevels)
        : // @ts-ignore R.dissoc is badly typed as <object, never>
          R.dissoc(position, prevState.skillLevels),
    }))
  }

  togglePosition = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    this.setState(
      (prevState) => ({
        selectedPositions: prevState.selectedPositions.includes(name)
          ? prevState.selectedPositions.filter((position) => position !== name)
          : [...prevState.selectedPositions, name],
      }),
      this.validate
    )
  }

  checkCustomWage = () => {
    const { customWage } = this.state
    if (customWage) {
      if (customWage < 0) {
        this.setState({
          isFormValid: false,
        })
      } else {
        this.setState(
          {
            isFormValid: true,
          },
          this.validate
        )
      }
    }
  }

  changeCustomWage = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      {
        customWage: value === '' ? undefined : +value,
      },
      this.checkCustomWage
    )
  }

  checkContractPercentage = () => {
    const { contractPercentage } = this.state
    if (contractPercentage && (contractPercentage <= 0 || contractPercentage > 100)) {
      this.setState({
        isFormValid: false,
      })
    } else {
      this.setState(
        {
          isFormValid: true,
        },
        this.validate
      )
    }
  }

  changeContractPercentage = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      {
        contractPercentage: value === '' ? undefined : +value,
      },
      this.checkContractPercentage
    )
  }

  save = async () => {
    const { businessId, groupId, closeModal, code, isExternalHire, loadingCallback, existingInvite, inviteInternalTemp } = this.props

    const {
      contractPercentage,
      customMessage,
      customWage,
      email,
      existingStaffer,
      hasConfirmedDialog,
      name,
      phone,
      selectedPositions,
      sendSms,
      skillLevels,
      stafferId,
      useCustomMessage,
    } = this.state

    if (selectedPositions.length === 0 && !hasConfirmedDialog && !inviteInternalTemp) {
      this.toggleConfirmationDialog()
      return
    }

    this.setState({
      isProcessing: true,
    })

    // just in case invoking like this to prevent unwanted early return
    const invokeLoadingCallback = (value: boolean) => {
      if (loadingCallback) {
        loadingCallback(value)
      }
    }

    try {
      if (inviteInternalTemp) {
        invokeLoadingCallback(true)
        await firestoreHttpsCallable('inviteStafferToFavoritePool', {
          code,
          groupId,
          businessId,
          email,
          name,
          phone: standardized(phone),
          recommendedPositions: selectedPositions,
          stafferId,
        })
        // Success toast
        const successMessage = existingInvite
          ? `${name}'s info has been updated!`
          : `${name} was ${!existingStaffer ? 'invited' : 'added to the favorite pool'}!`
        toast.success(successMessage)
      } else {
      invokeLoadingCallback(true)
      await firestoreHttpsCallable('inviteStafferToPool', {
        code,
        contractPercentage,
        customMessage: useCustomMessage && !!customMessage ? customMessage : '',
        customWage,
        email,
        isExternalHire: !!isExternalHire, // staffer hire fee will be charged
        name,
        permittedPositions: [...(selectedPositions || [])],
        phone: standardized(phone),
        poolId: businessId,
        sendSms,
        skillLevels,
        stafferId,
      })
      // Success toast
      const successMessage = existingInvite
        ? `${name}'s info has been updated!`
        : `${name} was ${!existingStaffer ? 'invited' : 'added to the pool'}!`
      toast.success(successMessage)
    }
    } catch (error: unknown) {
      errorToast((error as Error).message)
      console.error('[inviteStafferToPool] invite error: ', (error as Error).message)
    } finally {
      this.setState({ isProcessing: false })
      invokeLoadingCallback(false)
      closeModal()
    }
  }

  renderPhoneInput = () => (
    <PhoneInput
      inputProps={{
        autoFocus: true,
      }}
      autoFormat
      country={this.props.business?.region?.countryName === 'Sweden' ? 'se' : 'no'}
      disabled={!!this.props.existingInvite}
      enableAreaCodes
      enableLongNumbers
      inputStyle={{ width: '100%', border: 'none' }}
      key="phone"
      onChange={(phone, countryData) => this.changePhone(phone, countryData)}
      value={this.state.phone}
    />
  )

  onSelectStaffer = (event: { target: { value: StafferType } }) => {
    const { value: staffer } = event.target
    this.setState(
      {
        ...staffer,
        multipleStaffers: null,
      },
      this.validate
    )
  }

  render() {
    const {
      business,
      closeModal,
      existingInvite,
      groupJobTypes,
      isAlreadyInvited = false,
      isExternalHire,
      manager,
      inviteInternalTemp,
      jobTypes,
    } = this.props

    const {
      contractPercentage,
      customMessage,
      customWage,
      existingStaffer,
      isConfirmationDialogOpen,
      isFormValid,
      isProcessing,
      multipleStaffers,
      name,
      phone,
      selectedPositions,
      sendSms,
      skillLevels,
      useCustomMessage,
    } = this.state

    if (!business) {
      return <BeatLoader />
    }
    const jobTypePositions = (jobTypes && jobTypes.values) || []
    const modalTitle = inviteInternalTemp ? 'Invite an employee to internal temps' : 'Invite an employee to your internal network'
    const groupPositions = (groupJobTypes && groupJobTypes.values) || []

    // it's okay if it is undefined (optional input)
    const isWageValid = customWage === undefined || customWage === null ? true : customWage > 0
    const isContractPercentageValid =
      contractPercentage !== undefined ? contractPercentage >= 0 && contractPercentage <= 100 : true

    // If the business has Other businessType,
    // they do not have access to public permissions
    if (groupPositions.length === 0 && business && business.businessType === 'Other') {
      return (
        <React.Fragment>
          <ModalHeader close={closeModal}>{modalTitle}</ModalHeader>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Box p={4}>
                {manager && manager.groupId ? (
                  <Typography className={muiModalStyles.infoHeader}>
                    To invite an employee, your group first needs to{' '}
                    <Link to="/group/dashboard">create at least one custom position.</Link>
                  </Typography>
                ) : (
                  <Typography className={muiModalStyles.infoHeader}>
                    To invite an employee, your group first needs to create at least one custom position.
                    {manager && // To make sure we only show this once manager is fetched
                      ' Please contact your group manager to create one.'}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }

    const isMultipleMatches = !!(multipleStaffers && multipleStaffers.length > 0)
    const noPositions = groupPositions.filter((position) => !position.isArchived).length === 0
    const disabledFullName = !!existingStaffer || !!existingInvite || !!isExternalHire || isMultipleMatches

    return (
      <React.Fragment>
        <ModalHeader close={closeModal}>{modalTitle}</ModalHeader>
        {isAlreadyInvited && (
          <Box p={3}>
            <Alert severity="warning">{`Staffer ${name} with phone: ${phone} has already been invited`}</Alert>
          </Box>
        )}
        <Box p={3} className={muiModalStyles.modalContent}>
          <Grid container spacing={2}>
            {isMultipleMatches && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>Multiple staffers found</AlertTitle>
                  Found multiple staffers with the same phone numbers. Please{' '}
                  <strong>select which one you want to invite!</strong>
                  <FormControl fullWidth>
                    <InputLabel>Select who to invite</InputLabel>
                    {/* @ts-ignore MUI Select has incorrectly typed onChange */}
                    <Select onChange={this.onSelectStaffer}>
                      {multipleStaffers.map((staffer) => (
                        // MUI MenuItem is typed to string | number | readonly string[] | undefined
                        // @ts-ignore
                        <MenuItem key={staffer?.existingStaffer || ''} value={staffer}>
                          {staffer?.existingStaffer || ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body2">
                Add phone number, full name, and positions that match their experience.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!!isExternalHire || isMultipleMatches}
                fullWidth
                label="Phone"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: this.renderPhoneInput,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disabledFullName}
                fullWidth
                label="Name"
                onChange={this.changeName}
                placeholder="Full name"
                value={existingStaffer || name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
            {business && !inviteInternalTemp && (
              <Grid item xs={12}>
                <TextField
                  error={!isWageValid}
                  fullWidth
                  helperText={!isWageValid ? 'Please either enter a valid wage or remove it' : ''}
                  inputProps={{ min: 0, max: 99999, type: 'number' }}
                  label="Hourly wage (Optional)"
                  onChange={this.changeCustomWage}
                  placeholder="This is optional and can be added later"
                  value={customWage !== undefined && customWage !== null ? `${+customWage}` : ''} // this way to hide leading zeroes
                  variant="outlined"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Money color="primary" />
                      </InputAdornment>
                    ),
                    endAdornment: <InputAdornment position="end">${getCurrencyByBusiness(business)}</InputAdornment>,
                  }}
                />
              </Grid>
            )}
            {!inviteInternalTemp && (
              <>
              <Grid item xs={12}>
                <TextField
                  error={!isContractPercentageValid}
                  fullWidth
                  helperText={!isContractPercentageValid ? 'Please either enter a valid contract percentage or remove it' : ''}
                  inputProps={{ min: 1, max: 100, type: 'number' }}
                  label="Contract in percentage (Optional)"
                  onChange={this.changeContractPercentage}
                  placeholder="Add the same percentage that this employee has in their regular contract"
                  // @ts-ignore It's okay if its possibly undefined
                  value={`${+contractPercentage}`} // this way to hide leading zeroes
                  variant="outlined"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    startAdornment: <div />,
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }} />
              </Grid>
              <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={sendSms} onChange={this.toggleSendSms} name="sendSms" color="primary" />}
                    label="Send sms to the newly invited employee" />
                  {!sendSms && (
                    <Box mt={1}>
                      <Alert severity="info">
                        Activate toggle to send out an invite SMS. By having the toggle off, you can add positions to your
                        employee now and invite them later.
                      </Alert>
                    </Box>
                  )}
                  {sendSms && (
                    <Box display="flex" flexDirection="column">
                      <FormControlLabel
                        control={<Checkbox checked={useCustomMessage} onChange={this.toggleCustomMessage} />}
                        label="Use a custom message" />
                      {useCustomMessage && (
                        <Box textAlign="center">
                          <TextareaAutosize
                            aria-label="custom-sms"
                            onChange={this.handleCustomMessageChange}
                            placeholder="Write custom message here..."
                            style={{ width: 300, height: 80 }}
                            value={customMessage} />
                        </Box>
                      )}
                    </Box>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          {!inviteInternalTemp ? (
          <Box mt={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h3">
                  What access would you like to give to this employee?
                </Typography>
              </Grid>
              {groupPositions.length > 0 && (
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Group positions</Typography>
                  </Grid>
                  <Grid container item spacing={3}>
                    {groupPositions
                      .filter((position) => !position.isArchived)
                      .map((position, index) => (
                        <PoolPermissionsItem
                          availableSkillLevels={position.skillLevels}
                          currentSkillLevel={(skillLevels && skillLevels[position.name]) || ''}
                          index={index}
                          isChecked={selectedPositions.includes(position.name)}
                          isProcessing={isProcessing}
                          key={index}
                          position={position.name}
                          togglePosition={this.togglePosition}
                          updateSkillLevel={this.updateSkillLevel}
                        />
                      ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {noPositions && (
              <Box width="100%" display="flex" justifyContent="center">
                <Empty label="No custom positions available, please set some up to continue" />
              </Box>
            )}
          </Box>
          ) : (
            <Box mt={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h3">
                  What jobs do you recommend for this person?
                </Typography>
              </Grid>
              {jobTypePositions.length > 0 && (
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Public positions</Typography>
                  </Grid>
                  <Grid container item spacing={3}>
                    {jobTypePositions.map((position, index) => (
                      <PoolPermissionsItem
                        currentSkillLevel=""
                        index={index}
                        isChecked={selectedPositions.includes(position.name)}
                        isProcessing={isProcessing}
                        key={index}
                        position={position.name}
                        togglePosition={this.togglePosition}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
          )}
        </Box>
        <Box p={3}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={4}>
              {isProcessing ? (
                <Box textAlign="center">
                  <BeatLoader />
                </Box>
              ) : (
                <Tooltip
                  disableFocusListener={isFormValid}
                  disableHoverListener={isFormValid}
                  disableTouchListener={isFormValid}
                  leaveDelay={TooltipDelays.WITHOUT_LINKS}
                  title={this.getTooltip()}
                >
                  <div>
                    <Button
                      color="primary"
                      disabled={!isFormValid || isProcessing || isMultipleMatches || (!inviteInternalTemp && noPositions)}
                      fullWidth
                      onClick={this.save}
                      variant="contained"
                    >
                      {(existingStaffer && 'Add to internal network') ||
                        (!sendSms && 'Save invitation') ||
                        `${existingInvite ? 'Resend' : 'Send'} invitation`}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          {!sendSms && !inviteInternalTemp && (
            <Box mt={2}>
              <Alert severity="info">
                Activate toggle to send out an invite SMS. By having the toggle off, you can add positions to your
                employee now and invite them later.
              </Alert>
            </Box>
          )}
        </Box>
        <Dialog
          open={isConfirmationDialogOpen}
          onClose={this.toggleConfirmationDialog}
          aria-label="Confirm employee position assignment"
        >
          <DialogTitle>{`Allow position assignment for ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`You have not assigned any positions to ${name}.
                This means upon accepting the invite the employee will be able to manually select which positions he/she will have access to.

                Are you sure you want to continue?
              `}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleConfirmationDialog} color="secondary">
              Go back
            </Button>
            <Button onClick={this.confirmEmployeePositionsAssignment} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default connectFirestore(
  (db: Firestore, { businessId, code, groupId }: Props, uid: string) => ({
    business: getBusinessById(db, businessId),
    existingInvite: code ? getInvitedStafferByCode(db, code) : null,
    groupJobTypes: groupId && getGroupJobTypesById(db, groupId),
    manager: getManagerById(db, uid),
    jobTypes: getJobTypes(db),
  }),
  (props: Props) => {
    if (props.existingInvite === undefined || (props.groupId && props.groupJobTypes === undefined)) {
      return (
        <Box p={3} textAlign="center">
          <BeatLoader color="gray" />
        </Box>
      )
    }
    return <InviteStafferToPool {...props} />
  }
)
