import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import { firebaseDefaults } from '../../../../config'

type AnyObject = {
  [key: string]: any
}

// TODO Convert into environment variable soon
// NOTE `local-setup` uncomment to enable
// const isUseFunctionsEmulator = false

export async function firestoreHttpsCallable (
  functionName: string,
  data: AnyObject
): Promise<AnyObject> 
{
  const firebaseApp = firebase.app()
  const functionsInstance = firebaseApp.functions(firebaseDefaults.functions?.regionOrCustomDomain)

  // NOTE `local-setup`
  // if (isUseFunctionsEmulator) {
  //   functionsInstance.useEmulator('localhost', 5001)
  // }

  return functionsInstance.httpsCallable(functionName)(data)
}
