import type { Firestore, PossibleFirestores } from '../../../../../src/types/firebase'
import type { GroupInternalTempType, GroupType } from '../../../../../src/types/groups'
import type { PermissionsType, PermissionsTypeWithId, PoolType } from '../../../../../src/types/pools'
import { getPoolsByEmployeeId } from './pools.legacy'

export const getGroupById = (db: Firestore, groupId: string) => db.collection('groups').doc(groupId)

// This will return array of all docs (should be just one) which business
// is part of.
export const getGroupsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('groups').where('businesses', 'array-contains', businessId)

export const getGroupByIdPromise = async (db: Firestore, groupId: string): Promise<GroupType | null> => {
  const groupDoc = await getGroupById(db, groupId).get()
  if (groupDoc.exists) {
    return groupDoc.data() as GroupType
  }
  return null
}

export const getAllGroups = (db: Firestore) => db.collection('groups')

export const getGroupJobTypesById = (db: Firestore, groupId: string) =>
  db.collection('groups').doc(groupId).collection('data').doc('customJobTypes')

export const getGroupStaffers = (db: Firestore, groupId: string) =>
  db.collection('groups').doc(groupId).collection('staffers')

export const getGroupStaffersPermissions = async (
  db: Firestore,
  groupId: string
): Promise<PermissionsTypeWithId[] | null> => {
  const group = await getGroupByIdPromise(db, groupId)
  if (!group) {
    return null
  }
  const { businesses } = group

  // Fetch staffers permissions for each business separately
  const docsByBus = await Promise.all(
    businesses.map(async (businessId: string) => {
      const { docs } = await db.collection('staffersPermissions').orderBy(`businessPermissions.${businessId}`).get()
      return docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as PermissionsType),
      }))
    })
  )
  // flatten arrays while removing duplicities
  return docsByBus.reduce((uniqDocs, docsOfBus) => {
    docsOfBus.forEach((doc) => {
      if (!uniqDocs.find(({ id }) => id === doc.id)) {
        uniqDocs.push(doc)
      }
    })
    return uniqDocs
  }, [] as PermissionsTypeWithId[])
}

export const getGroupStaffer = async (db: PossibleFirestores, groupId: string, stafferId: string) =>
  db.collection('groups').doc(groupId).collection('staffers').doc(stafferId)

export const getIsGroupStaffer = async (db: Firestore, groupId: string, stafferId: string): Promise<boolean> => {
  const group = await getGroupByIdPromise(db, groupId)
  if (!group) {
    return false
  }
  const { businesses } = group

  if (businesses && businesses.length > 0) {
    const { docs } = await getPoolsByEmployeeId(db, stafferId).get()
    const pools = docs.map((poolDoc) => poolDoc.data() as PoolType)

    return !!businesses.some((businessId) => pools.find((pool) => pool.businessId === businessId))
  }
  return false
}

export const getGroupCustomJobTypes = (db: Firestore, groupId: string) =>
  db.collection('groups').doc(groupId).collection('data').doc('customJobTypes')

export const getGroupsWithBusiness = (db: Firestore, businessId: string) =>
  getAllGroups(db).where('businesses', 'array-contains', businessId)

export const getGroupInternalTemps = (db: Firestore, groupId: string) =>
  db.collection('groups').doc(groupId).collection('internalTemps').where('isDeleted', '==', false)

export const getGroupInternalTempsByBusinessId = (db: Firestore, groupId: string, businessId: string) => {
  const queryRef = getGroupInternalTemps(db, groupId)
  return queryRef.where('businessId', '==', businessId).where('isDeleted', '==', false)
}