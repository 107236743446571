import { IconButton } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import type { ReactNode } from 'react'
import styles from './StafferProfileModal.module.css'

export type ModalHeaderProps = {
  onClose: VoidFunction
  title: string
  rightActions?: ReactNode
  leftActions?: ReactNode
}

export const StafferProfileModalHeader = ({ title, onClose, rightActions, leftActions }: ModalHeaderProps) => (
  <div className={styles.modalHeader}>
    {leftActions && <div className={styles.modalHeaderLeftActions}>{leftActions}</div>}
    <h4 className={styles.modalHeaderTitle}>{title}</h4>
    <div className={styles.modalHeaderRightActions}>
      {rightActions}
      <IconButton onClick={onClose}>
        <CloseRounded />
      </IconButton>
    </div>
  </div>
)
