import { Box, Button, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { arrayRemove, arrayUnion } from 'firebase/firestore'
import * as R from 'ramda'
import React, { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { InviteType } from '../../../../src/types/common'
import type { Firestore } from '../../../../src/types/firebase'
import type { PoolType } from '../../../../src/types/pools'
import { errorToast } from '../../helpers/toast'
import { getJobTypes } from '../../staffers/api/getters/common'
import { getExistingInviteByUsedBy, getPoolsByEmployeeId } from '../../staffers/api/getters/pools'
import { getStafferPermissions } from '../../staffers/api/getters/staffer'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import Checkbox from '../checkbox/CustomCheckbox'
import modalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import InternalTempIcon from '../../img/InternalTempIcon.svg'

type State = {
  saveInProgress: boolean
  allowedPositionsFetched: boolean
  allowedPositions: string[]
  newlyAllowedPositions: string[]
  removedPositions: string[]
}

type JobType = {
  name: string
  skillCategory: number
}

type Props = {
  stafferId: string
  isLoading?: boolean
  jobTypes?: { values: JobType[] } | null
  inPools?: PoolType[] | null
  // eslint-disable-next-line react/no-unused-prop-types
  permissions?: { positions: string[] } | null
  stafferInvite?: InviteType | null
  onEditPermissionSuccess?: () => any
  closeModal: () => any
}

class EditStafferPermissions extends Component<Props, State> {
  state = {
    saveInProgress: false,
    allowedPositionsFetched: false,
    allowedPositions: [] as string[],
    newlyAllowedPositions: [] as string[],
    removedPositions: [] as string[],
  }

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    const { allowedPositionsFetched, saveInProgress } = state
    const { permissions, jobTypes, stafferInvite } = props

    if (jobTypes && permissions !== undefined && !allowedPositionsFetched) {
      return {
        saveInProgress,
        allowedPositionsFetched: true,
        allowedPositions:
          (permissions && permissions.positions) ||
          stafferInvite?.recommendedPositions ||
          jobTypes.values.filter((type) => type.skillCategory === 1).map((type) => type.name),
        newlyAllowedPositions: [] as string[],
        removedPositions: [] as string[],
      }
    }

    return null
  }

  render() {
    const { jobTypes, closeModal, inPools, isLoading, stafferInvite } = this.props
    const { saveInProgress } = this.state

    if (!jobTypes || saveInProgress || isLoading || inPools === undefined || stafferInvite === undefined) {
      return (
        <Box p={3} className={modalStyles.modalContent} textAlign="center">
          <BeatLoader />
        </Box>
      )
    }

    const allTypes = R.sort((type1, type2) => type1.name.localeCompare(type2.name), jobTypes.values)
    const allTypesLeft = allTypes.filter((_, index) => index % 2 === 0)
    const allTypesRight = allTypes.filter((_, index) => index % 2 === 1)
    const invitedPositions = stafferInvite?.positions || []

    return (
      <React.Fragment>
        <ModalHeader close={closeModal}>Edit Staffer Permissions</ModalHeader>
        <Box p={3} className={modalStyles.modalContent}>
          {!!(inPools || []).length && (
            <Alert severity="warning">
              {`This staffer is a pool staffer. His access to external positions posted within the network
              is handled individually by the internal business and won't be affected by these permissions
              for jobs that are posted by these businesses`}
            </Alert>
          )}
          <Grid container>
            {stafferInvite && stafferInvite.internalTemp && (
              <Grid item xs={12}>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', marginBottom: 12 }}>
                  <img src={InternalTempIcon} alt="Internal Temp Icon" style={{ width: 24 }} />
                  <span>Invited by <span style={{ fontWeight: 600 }}>{stafferInvite.groupName}</span></span>
                </div>
                  <div style={{ display: 'flex', gap: 5 }}>
                    <span style={{ fontWeight: 600 }}>Recommended positions: </span>
                    <span>
                      {stafferInvite.recommendedPositions && stafferInvite.recommendedPositions.length > 0
                        ? stafferInvite.recommendedPositions.join(', ')
                        : 'No recommended positions'
                      }
                    </span>
                  </div>
            </Grid>
            )}
            <Grid item xs={6}>
              {allTypesLeft.map((jobType) => (
                <div key={jobType.name}>
                  <Checkbox
                    checked={this.isAllowedPosition(jobType)}
                    onToggleCheckbox={() => this.onTogglePosition(jobType)}
                    title={jobType.name}
                  />
                  {invitedPositions.includes(jobType.name) && (
                    <Box component="span" marginLeft={2} color="#4CAF50">
                      &#x2022;
                    </Box>
                  )}
                </div>
              ))}
            </Grid>
            <Grid item xs={6}>
              {allTypesRight.map((jobType) => (
                <div key={jobType.name}>
                  <Checkbox
                    checked={this.isAllowedPosition(jobType)}
                    onToggleCheckbox={() => this.onTogglePosition(jobType)}
                    title={jobType.name}
                  />
                  {invitedPositions.includes(jobType.name) && (
                    <Box component="span" marginLeft={2} color="#4CAF50">
                      &#x2022;
                    </Box>
                  )}
                </div>
              ))}
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={3}>
              <Button fullWidth variant="contained" onClick={closeModal}>
                Cancel changes
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth color="primary" variant="contained" onClick={this.save}>
                Save changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }

  isAllowedPosition = (jobType: JobType): boolean => {
    const { allowedPositions } = this.state
    return allowedPositions.includes(jobType.name)
  }

  onTogglePosition = (jobType: JobType) => {
    const { allowedPositions, newlyAllowedPositions, removedPositions } = this.state
    if (this.isAllowedPosition(jobType)) {
      this.setState({
        // eslint-disable-next-line max-len
        allowedPositions: allowedPositions.filter((allowedPosition) => allowedPosition !== jobType.name),
        newlyAllowedPositions: newlyAllowedPositions.filter(
          (newlyAllowedPosition) => newlyAllowedPosition !== jobType.name
        ),
        removedPositions: [...removedPositions, jobType.name],
      })
    } else {
      this.setState({
        allowedPositions: [...allowedPositions, jobType.name],
        newlyAllowedPositions: [...newlyAllowedPositions, jobType.name],
        removedPositions: removedPositions.filter((removedPosition) => removedPosition !== jobType.name),
      })
    }
  }

  save = async () => {
    const { allowedPositions, allowedPositionsFetched, saveInProgress, newlyAllowedPositions, removedPositions } =
      this.state
    const { stafferId, closeModal, onEditPermissionSuccess } = this.props

    if (saveInProgress || !allowedPositionsFetched) {
      return
    }

    try {
      this.setState({ saveInProgress: true })

      const database = firebase.firestore()
      // Save the job permissions
      const permissionsRef = database.collection('staffersPermissions').doc(stafferId)
      await permissionsRef.set({ positions: allowedPositions }, { merge: true })

      const preferredPositionsRef = database.collection('preferredPositions').doc(stafferId)
      if (newlyAllowedPositions.length !== 0) {
        await preferredPositionsRef.update({ values: arrayUnion(...newlyAllowedPositions) })
      }
      if (removedPositions.length !== 0) {
        await preferredPositionsRef.update({ values: arrayRemove(...removedPositions) })
      }

      // On-success callback
      if (onEditPermissionSuccess) {
        onEditPermissionSuccess()
      }

      // Close the modal
      closeModal()

      toast.success('Permissions were updated successfully.')
    } catch (error) {
      this.setState({ saveInProgress: false })
      errorToast('There was error while saving permissions.')
      console.error((error as Error)?.message)
    }
  }
}

export default connectFirestore(
  (db: Firestore, props: Props) => ({
    permissions: getStafferPermissions(props.stafferId),
    jobTypes: getJobTypes(),
    inPools: getPoolsByEmployeeId(props.stafferId),
    stafferInvite: getExistingInviteByUsedBy(props.stafferId),
  }),
  EditStafferPermissions
)
