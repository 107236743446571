import type { SelectProps } from '@material-ui/core'
import { FormControl, Select as MuiSelect, withStyles } from '@material-ui/core'
import type { PropsWithChildren } from 'react'

type Props = SelectProps

const CustomWrapper = withStyles({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: '4px',
    },
  },
})(FormControl)

const CustomSelect = withStyles({
  root: {
    fontSize: '12px',
    display: 'inline-flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  select: {
    padding: '6px',
  },
})(MuiSelect)

const Select = ({ children, ...rest }: PropsWithChildren<Props>) => (
  <CustomWrapper fullWidth variant="outlined">
    <CustomSelect {...rest}>{children}</CustomSelect>
  </CustomWrapper>
)

export default Select
