import { IconButton, Modal } from '@material-ui/core'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import TodayIcon from '@mui/icons-material/Today'
import moment from 'moment'
import type { FC } from 'react'
import { useState } from 'react'
import type { Timestamp } from '../../../../src/types/firebase'
import styles from './StafferProfileModal.module.css'
import { StafferProfileModalHeader } from './StafferProfileModalHeader'

type Props = {
  startDate: Timestamp
  endDate: Timestamp | null
  jobTitle: string
  jobCompany: string
  jobType: string
  reference?: string
  referencePhone?: string
  referenceEmail?: string
  style?: React.CSSProperties
}

const StafferProfileExperienceCard: FC<Props> = ({
  startDate,
  endDate,
  jobTitle,
  jobCompany,
  jobType,
  reference,
  referenceEmail,
  referencePhone,
  style,
}) => {
  const [isContactOpen, setIsContactOpen] = useState(false)
  const startTime = moment(startDate.toMillis()).format('MM.YYYY')
  const endTime = endDate ? moment(endDate.toMillis()).format('MM.YYYY') : 'Present'
  const experienceMonths = moment(endDate?.toMillis()).diff(moment(startDate?.toMillis()), 'months')
  const experienceText =
    experienceMonths < 1
      ? '-'
      : experienceMonths < 12
      ? `${experienceMonths}m`
      : `${Math.round(experienceMonths / 12)}y`
  return (
    <>
      <div className={styles.rowContainer} style={{ ...style, padding: '10px 0' }}>
        <div className={styles.experienceRow}>
          <span className={styles.greenTextBold}>{jobTitle}</span>
          <span>-</span>
          <span className={styles.largeText}>{jobCompany}</span>
          <span>-</span>
          <span className={styles.greyTextLarge}>{jobType}</span>
        </div>
        <div className={styles.experienceRow} style={{ minWidth: 280, justifyContent: 'space-between' }}>
          <div className={styles.experienceRow}>
            <TodayIcon className={styles.greyIcon} />
            <span>{`${startTime} - ${endTime} (${experienceText})`}</span>
          </div>
          <IconButton className={styles.contactBtn} onClick={() => setIsContactOpen(true)}>
            <PhoneOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <Modal
        hideBackdrop
        open={isContactOpen}
        onClose={() => setIsContactOpen(false)}
        style={{ backgroundColor: 'rgba(0,0,0,0.35)', zIndex: 30 }}
        className={styles.modal}
      >
        <div className={styles.contactModal}>
          <StafferProfileModalHeader title="Contact detail" onClose={() => setIsContactOpen(false)} />
          <div className={styles.contactContent}>
            <div className={styles.contactContentRow} style={{ borderBottom: '1px solid #E5E5E5' }}>
              <div className={styles.contentRow} style={{ gap: 10 }}>
                <PersonOutlineOutlinedIcon className={styles.greyIcon} />
                <span className={styles.greyTextLarge}>Contact name</span>
              </div>
              {reference ? <span>{reference}</span> : <span className={styles.emptySection}>Not provided</span>}
            </div>
            <div className={styles.contactContentRow} style={{ borderBottom: '1px solid #E5E5E5' }}>
              <div className={styles.contentRow} style={{ gap: 10 }}>
                <PhoneOutlinedIcon className={styles.greyIcon} />
                <span className={styles.greyTextLarge}>Phone number</span>
              </div>
              {referencePhone ? (
                <span>{referencePhone}</span>
              ) : (
                <span className={styles.emptySection}>Not provided</span>
              )}
            </div>
            <div className={styles.contactContentRow}>
              <div className={styles.contentRow} style={{ gap: 10 }}>
                <EmailOutlinedIcon className={styles.greyIcon} />
                <span className={styles.greyTextLarge}>Email</span>
              </div>
              {referenceEmail ? (
                <span>{referenceEmail}</span>
              ) : (
                <span className={styles.emptySection}>Not provided</span>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StafferProfileExperienceCard
