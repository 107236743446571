import * as R from 'ramda'
import type { BusinessType } from '../../../../../src/types/business'
import type { Firestore, PossibleFirestores } from '../../../../../src/types/firebase'
import type { JobType } from '../../../../../src/types/jobs'
import type { PermissionsType, PoolType } from '../../../../../src/types/pools'
import type { StafferType } from '../../../../../src/types/staffer'

export const getStafferReferrals = (db: Firestore, uid: string | null) =>
  db.collection('staffersReferrals').doc(uid || undefined)

export const getStafferReferralsPromise = (db: Firestore, uid: string | null) =>
  db
    .collection('staffersReferrals')
    .doc(uid || undefined)
    .get()
    .then((doc) => (doc.exists ? doc.data() : null))

export const getStafferPermissions = (db: PossibleFirestores, uid: string | null) =>
  db.collection('staffersPermissions').doc(uid || undefined)

export const getStafferPermissionsByIdPromise = async (
  db: Firestore,
  uid: string
): Promise<PermissionsType | undefined> => {
  const permissionsDoc = await getStafferPermissions(db, uid).get()
  return permissionsDoc.data() as PermissionsType | undefined
}

export const getStafferById = (db: PossibleFirestores, uid: string | null) =>
  db.collection('staffers').doc(uid || undefined)

export const getStaffersByPhone = (db: Firestore, phone: string | null) =>
  db.collection('staffers').where('phone', '==', phone)

export const getStaffersByEmail = (db: Firestore, email: string) =>
  db.collection('staffers').where('email', '==', email)

export const getAllStaffers = (db: PossibleFirestores) =>
  db.collection('staffers').where('accessGranted', '>', new Date(0)).orderBy('accessGranted', 'desc')

export const getAllStaffersGranted = (db: Firestore) =>
  db
    .collection('staffers')
    // This effectively filters out documents where accessGranted is not of type Date
    .where('accessStatus', '==', 'granted')
    .where('accessGranted', '>', new Date(0))
    .orderBy('accessGranted', 'desc')

export const getAllStaffersRequested = (db: Firestore) =>
  db.collection('staffers').where('accessGranted', '==', false).orderBy('accessRequested', 'desc')

export const getUnresolvedRegistrationsStaffers = (db: Firestore) =>
  db
    .collection('staffers')
    .where('accessGranted', '==', false)
    // Only staffers with unfinished onboarding have this step
    .where('onboardingStep', 'in', [1, 2, 3, 4, 5])
    .orderBy('accessRequested', 'desc')

export const getEvaluatedStaffers = (db: Firestore) =>
  db.collection('staffers').where('accessStatus', '==', 'evaluated').orderBy('updatedAt', 'desc')

export const getNewRequestsStaffers = (db: Firestore) =>
  db.collection('staffers').where('accessStatus', '==', 'applied').orderBy('updatedAt', 'desc')

export const getUpdatedStaffers = (db: Firestore) =>
  db
    .collection('staffers')
    .where('accessGranted', '==', false)
    .where('accessStatus', '==', 'updated')
    .orderBy('updatedAt', 'desc')

export const getReferenceCallingStaffers = (db: Firestore) =>
  db.collection('staffers').where('accessStatus', '==', 'calling').orderBy('updatedAt', 'desc')

export const getDeniedStaffers = (db: Firestore) =>
  db.collection('staffers').where('accessStatus', '==', 'denied').orderBy('updatedAt', 'desc')

// stafferDetails/{id}/regionalDetails/{country}/verified === false
export const getPendingTaxCardStafferDetails = (db: Firestore, countryName: string) =>
  db
    .collection('staffersDetails')
    .where(`regionalDetails.${countryName}.taxCardVerified`, '==', false)
    .orderBy('updatedAt', 'desc')

export const getStafferCertificateDetails = (db: Firestore) =>
  db.collection('staffersDocuments').where('cv', '!=', null)

export const getStafferDetailsById = (db: PossibleFirestores, uid: string | null) =>
  db.collection('staffersDetails').doc(uid || undefined)

export const getStaffersShiftsByShiftIdPromise = async (db: Firestore, shiftId: string, job: JobType) =>
  db
    .collection('jobs')
    .doc(job.id)
    .collection('staffers')
    .where('shiftsConfirmed', 'array-contains', shiftId)
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({
        stafferId: doc.id,
        shift: (doc.data() as JobType).shifts.find((shift) => shift.shiftId === shiftId),
        businessName: job.businessName,
        jobType: job.jobType,
        jobId: job.id,
        shiftId,
      }))
    )

export const getStafferLogsById = (db: Firestore, uid: string | null) =>
  db
    .collection('staffers')
    .doc(uid || undefined)
    .collection('logs')
    .orderBy('time', 'desc')
    .limit(150)

export const getStafferLogsByJobId = (db: Firestore, stafferId: string, jobId: string) =>
  db.collection('staffers').doc(stafferId).collection('logs').where('jobId', '==', jobId).limit(15)

export const canBusinessAccessDocumentsPromise = (
  db: Firestore,
  businessId: string,
  stafferId: string
): Promise<boolean> =>
  db
    .collection('staffers')
    .doc(stafferId)
    .get()
    .then(
      (stafferDoc) =>
        stafferDoc.exists && ((stafferDoc.data() as StafferType).canBusinessAccessDocuments || {})[businessId]
    )

export const getStafferDocumentsById = (db: Firestore, stafferId: string) =>
  db.collection('staffersDocuments').doc(stafferId)

export const getStafferByIdPromise = async (db: PossibleFirestores, uid: string | null): Promise<StafferType | null> =>
  db
    .collection('staffers')
    .doc(uid || undefined)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const stafferData = doc.data() as StafferType
        if (!R.isEmpty(stafferData)) {
          return {
            ...stafferData,
            id: doc.id,
          }
        }
      }
      return null
    })

export const getStafferRatings = (db: Firestore, stafferId: string) =>
  db.collection('staffers').doc(stafferId).collection('ratings')

export const getStafferRatingsFromBusiness = (db: Firestore, stafferId: string, businessId: string) =>
  db.collection('staffers').doc(stafferId).collection('ratings').doc(businessId)

export const getStaffersReferralsOrdered = (db: Firestore) => db.collection('staffersReferrals').orderBy('due', 'desc')

export const getStafferReferralsById = (db: Firestore, uid: string) =>
  db.collection('staffers').where('referralId', '==', uid).orderBy('accessGranted', 'desc')

export const getStaffersByUpdatedExperience = (db: Firestore) =>
  db
    .collection('staffers')
    .where('experiencesUpdatedAt', '>', new Date(0))
    .where('accessStatus', '==', 'granted') // This might cause an issue where employees are not
    // showing up when they update their experience, question is - why should they?
    // If you are fixing this bug, please contact me beforehand @oliver
    .orderBy('experiencesUpdatedAt', 'desc')

export const getStaffersByTotalShifts = (db: Firestore) => db.collection('staffers').orderBy('totalShifts', 'desc')

export const getStaffersByLastLogin = (db: Firestore) => db.collection('staffers').orderBy('lastLoggedAt', 'desc')

export const getStaffersByDocumentsUpdate = (db: Firestore) =>
  db.collection('staffers').where('accessStatus', '==', 'granted').orderBy('documentsUpdate', 'desc')

export const getStaffersOnCallingList = (db: Firestore) => db.collection('staffers').where('callingList', '==', true)

export const getStaffersWithPermissions = (db: Firestore, jobType: string) =>
  db.collection('staffersPermissions').where('positions', 'array-contains', jobType)

export const getAllStaffersPermissions = (db: Firestore) =>
  db.collection('staffersPermissions').orderBy('businessPermissions', 'asc')

export const getStafferDetailsByCountry = (db: Firestore, country: string) =>
  db.collection('staffersDetails').where('address.country', '==', country)

export const getStafferEmployers = async (db: Firestore, stafferId: string, connectedBusinesses: string[]) => {
  const allEmployerPools = await db.collection('pools').where('employees', 'array-contains', stafferId).get()

  if (allEmployerPools) {
    const connectedEmployerPools: string[] = allEmployerPools.docs
      .filter((doc) => connectedBusinesses.includes(doc.id))
      .map((doc) => doc.id)
    if (connectedEmployerPools.length > 0) {
      const connectedEmployerBusinesses = await Promise.all(
        connectedEmployerPools.map(async (poolId) => {
          const businessDoc = await db.collection('business').doc(poolId).get()
          if (businessDoc.exists) {
            const business = businessDoc.data() as BusinessType
            return business
          }
          return null
        })
      )
      return connectedEmployerBusinesses.filter((business) => !!business) || []
    }
    return []
  }
  return []
}

export const needsShiftReview = async (db: Firestore, stafferId: string, connectedBusinesses: string[]) => {
  const stafferEmployerBusinesses = await getStafferEmployers(db, stafferId, connectedBusinesses)
  return (stafferEmployerBusinesses || []).some((business) => business && !!business.requiresShiftReview)
}

export const isEmployeeInPoolsOf = async (db: Firestore, stafferId: string, businessId: string): Promise<boolean> => {
  const pool = await db.collection('pools').doc(businessId).get()
  const poolData = pool.data() as PoolType
  if (poolData) {
    // check connected pools
    const { employees } = poolData
    if (employees.includes(stafferId)) {
      return true
    }

    const connectedPools = await Promise.all(
      poolData.connectedBusinesses.map(async (connectedBusinessId) => {
        const connectedPoolDoc = await db.collection('pools').doc(connectedBusinessId).get()
        return connectedPoolDoc.data()
      })
    )
    return connectedPools.some((companyPoolData) => companyPoolData && companyPoolData.employees.includes(stafferId))
  }
  return false
}
