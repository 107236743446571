export type FirebaseDefaults = {
  functions?: {
    regionOrCustomDomain?: string
  }
}

export const firebaseDefaults: FirebaseDefaults = {
  functions: {
    regionOrCustomDomain: 'europe-west1',
  },
}

// Initialize Firebase
export const config = {
  apiKey: 'AIzaSyAvbFj12Xkub1KIF2eQkYtDAyHtqaeIOPg',
  authDomain: 'staffers-production.firebaseapp.com',
  databaseURL: 'https://staffers-production.firebaseio.com',
  projectId: 'staffers-production',
  storageBucket: 'staffers-production.appspot.com',
  messagingSenderId: '863917935747',
  appId: '1:863917935747:web:eae0984a2a6e61fff69fb6',
  adminTenantId: 'admins-vbk58', // ID of fribase Identity Platform tenant for admin accounts'
}

export const bigQueryDatasetName = 'analytics_185735795.*'
export const newBigQueryDatasetName = 'analytics_238575919.*'

export const webUrlBase = 'https://admin.staffersapp.com'
export const googlePlacesApiKey = 'AIzaSyAvbFj12Xkub1KIF2eQkYtDAyHtqaeIOPg'
export const dynamicLinkAddress = 'staffersapp'

// Information about dynamic links
export const dynamicLinkInfo = {
  apiKey: 'AIzaSyAvbFj12Xkub1KIF2eQkYtDAyHtqaeIOPg',
  domainUriPrefix: 'https://staffersapp.page.link',
  androidPackageName: 'com.staffersas.staffers',
  iosAppStoreId: '1433888801',
  iosBundleId: 'com.staffersas.staffers',
  iosCustomScheme: 'staffers',
  enableForcedRedirect: false,
  appId: '1:863917935747:web:eae0984a2a6e61fff69fb6',
}

export const algoliaInfo = {
  APP_ID: 'S87T0WZWXZ',
  SEARCH_KEY: '31d3ca2b5e1742e3e4b0ed50d03edbce',
}

export const SIGNICAT_SANDBOX = false

const isBeta = global?.location?.host === 'beta-staffers.firebaseapp.com'

export const appUrl = !isBeta
  ? 'https://app.staffersapp.com' // live
  : 'https://web-app-git-beta-staffersteam.vercel.app' // beta
export const jobsUrl = !isBeta
  ? 'https://jobs.staffers.no' // live
  : 'https://staffers-jobs-git-beta-staffersteam.vercel.app' // beta
export const integrationApiUrl = 'https://api.staffersapp.com'

export const manymoreApiUrl = `${integrationApiUrl}/manymore`

export const gleapKey = 'EMbApX7h2wKoaJIlBoEcCMjyZMP5eX1r'
export const gleapProjectId = '667c257563ce56b55c309ac1'
